@font-face {
	font-family: 'plbicons';
	src:url('icons/plbicons.eot?cmbh2f');
	src:url('icons/plbicons.eot?#iefixcmbh2f') format('embedded-opentype'),
		url('icons/plbicons.woff?cmbh2f') format('woff'),
		url('icons/plbicons.ttf?cmbh2f') format('truetype'),
		url('icons/plbicons.svg?cmbh2f#plbicons') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'plbicons';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Enable Ligatures ================ */
	letter-spacing: 0;
	-webkit-font-feature-settings: "liga";
	-moz-font-feature-settings: "liga=1";
	-moz-font-feature-settings: "liga";
	-ms-font-feature-settings: "liga" 1;
	-o-font-feature-settings: "liga";
	font-feature-settings: "liga";

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
	content: "\e607";
}

.icon-apcbrand:before {
	content: "\e606";
}

.icon-socialize:before {
	content: "\e605";
}

.icon-prizes:before {
	content: "\e608";
}

.icon-gear:before {
	content: "\e604";
}

.icon-location:before {
	content: "\e603";
}

.icon-dealer:before {
	content: "\e600";
}

.icon-leaderboard:before {
	content: "\e601";
}

.icon-apclogo:before {
	content: "\e602";
}

.icon-prizescircle:before {
	content: "\e93b";
}
