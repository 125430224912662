@font-face {
    font-family: 'ArvoRegular';
    src: url('/assets/fontkits/Arvo-Regular-webfont.eot');
    src: url('/assets/fontkits/Arvo-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fontkits/Arvo-Regular-webfont.woff') format('woff'), url('/assets/fontkits/Arvo-Regular-webfont.ttf') format('truetype'), url('/assets/fontkits/Arvo-Regular-webfont.svg#ArvoRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ArvoItalic';
    src: url('/assets/fontkits/Arvo-Italic-webfont.eot');
    src: url('/assets/fontkits/Arvo-Italic-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fontkits/Arvo-Italic-webfont.woff') format('woff'), url('/assets/fontkits/Arvo-Italic-webfont.ttf') format('truetype'), url('/assets/fontkits/Arvo-Italic-webfont.svg#ArvoItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ArvoBold';
    src: url('/assets/fontkits/Arvo-Bold-webfont.eot');
    src: url('/assets/fontkits/Arvo-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fontkits/Arvo-Bold-webfont.woff') format('woff'), url('/assets/fontkits/Arvo-Bold-webfont.ttf') format('truetype'), url('/assets/fontkits/Arvo-Bold-webfont.svg#ArvoBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'ArvoBoldItalic';
    src: url('/assets/fontkits/Arvo-BoldItalic-webfont.eot');
    src: url('/assets/fontkits/Arvo-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fontkits/Arvo-BoldItalic-webfont.woff') format('woff'), url('/assets/fontkits/Arvo-BoldItalic-webfont.ttf') format('truetype'), url('/assets/fontkits/Arvo-BoldItalic-webfont.svg#ArvoBoldItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Alfa';
    src: url('/assets/fontkits/AlfaSlabOne-Regular-OTF-webfont.eot');
    src: url('/assets/fontkits/AlfaSlabOne-Regular-OTF-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fontkits/AlfaSlabOne-Regular-OTF-webfont.woff') format('woff'), url('/assets/fontkits/AlfaSlabOne-Regular-OTF-webfont.ttf') format('truetype'), url('/assets/fontkits/AlfaSlabOne-Regular-OTF-webfont.svg#AlfaSlabOneRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gudea';
    src: url('/assets/fontkits/Gudea-Regular-webfont.eot');
    src: url('/assets/fontkits/Gudea-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fontkits/Gudea-Regular-webfont.woff') format('woff'), url('/assets/fontkits/Gudea-Regular-webfont.ttf') format('truetype'), url('/assets/fontkits/Gudea-Regular-webfont.svg#GudeaRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GudeaItalic';
    src: url('/assets/fontkits/Gudea-Italic-webfont.eot');
    src: url('/assets/fontkits/Gudea-Italic-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fontkits/Gudea-Italic-webfont.woff') format('woff'), url('/assets/fontkits/Gudea-Italic-webfont.ttf') format('truetype'), url('/assets/fontkits/Gudea-Italic-webfont.svg#GudeaItalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GudeaBold';
    src: url('/assets/fontkits/Gudea-Bold-webfont.eot');
    src: url('/assets/fontkits/Gudea-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('/assets/fontkits/Gudea-Bold-webfont.woff') format('woff'), url('/assets/fontkits/Gudea-Bold-webfont.ttf') format('truetype'), url('/assets/fontkits/Gudea-Bold-webfont.svg#GudeaBold') format('svg');
    font-weight: normal;
    font-style: normal;
}
