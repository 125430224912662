/***********************************************************************************************************************
zfilter Reset
************************************************************************************************************************/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/********************************* Classes for XHTML default Tag *********************************/
.loginbg {
  background: #323467;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1%, #323467),
    color-stop(43%, #333367),
    to(#022042)
  );
  background: linear-gradient(to bottom, #323467 1%, #333367 43%, #022042 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#323467', endColorstr='#022042',GradientType=0 );
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  background-attachment: fixed;
  width: 100%;
  height: 100vh;
}
.card-title.relogincard {
  color: #242c4f;
  font-family: alfa;
  font-size: 17px;
  margin: 0;
  padding: 5px 0;
}
.card-subtitle.relogincard {
  font-family: arvoregular;
  font-size: 12px;
}
.mat-drawer-container {
  color: inherit !important;
  background: none !important;
}
/* --------------------------------

Primary style

-------------------------------- */
*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  font-size: 10px !important;
}
body::after {
  clear: both;
  content: '';
  display: table;
}
body {
  color: #37474f;
  font-family: 'Roboto', sans-serif;
  font-size: 1.4rem;
  line-height: 1.6;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #37474f;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 1.2;
}
table {
  width: 100%;
}
.card-body .h1:first-child,
.card-body .h2:first-child,
.card-body .h3:first-child,
.card-body .h4:first-child,
.card-body .h5:first-child,
.card-body .h6:first-child,
.card-body h1:first-child,
.card-body h2:first-child,
.card-body h3:first-child,
.card-body h4:first-child,
.card-body h5:first-child,
.card-body h6:first-child {
  margin-top: 0;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.secondmenu-yes {
  margin-top: 100px;
}
a {
  text-decoration: none !important;
}
.nav-menu-item > a {
  color: #fff;
}
.account a {
  color: #fff;
}
.account a i {
  margin-right: 5px;
}
.navigation-icon {
  color: #686868;
  float: left;
  font-size: 20px !important;
  margin-top: 1px;
  text-align: center;
  width: 25px;
}
.navigation-label {
  display: table;
  margin: 0 0 0 33px;
}
.nav-menu-item > a {
  font-weight: bold;
  padding-left: 15px !important;
}
.card-icon {
  margin-right: 5px;
}
h1,
h2 {
  font-size: 1.6em;
  font-weight: bold;
  padding: 2px 0;
}
h3 {
  font-size: 1.6em;
  margin-bottom: 10px;
}
h4 {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 5px;
}
form,
img,
a,
li,
ul {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
}
a {
  color: #009;
  text-decoration: underline;
  outline: none;
}
a:hover {
  text-decoration: none;
}
p {
  line-height: 1.5em;
}
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #dfdfe8 inset !important;
  box-shadow: 0 0 0 30px #dfdfe8 inset !important;
}
.container-fluid.content-logic {
  display: table;
}
.panelrow .alert {
  margin-top: 10px;
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 7px !important;
  padding-left: 7px !important;
  float: left;
}
.capitalize {
  text-transform: capitalize;
}

/***********************************************************************************************************************
zfilter Material Override
************************************************************************************************************************/
.mat-form-field {
  width: 100%;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3b3e6b !important;
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3b3e6b !important;
}
pre {
  float: left;
}
.card-showall {
  float: left;
  margin-right: 15px;
  margin-top: 1px;
}
.mat-form-field-infix {
  padding: 10px 0 5px !important;
  width: auto !important;
}
.card-footer .mat-paginator {
  background: none;
}
.mat-paginator-page-size {
  position: absolute;
}
.mat-paginator-page-goto {
  position: absolute;
  left: 305px;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  margin-right: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 700;
  color: #3b3e6b;
  font-size: 13px;
}
.mat-paginator-range-actions {
  position: absolute;
  left: 5px;
}

#sample_player_form .mat-paginator-range-actions {
  left: 25px;
}

.mat-paginator-range-label {
  position: absolute;
  right: -150px;
}
.mat-paginator-page-goto-label {
  float: left;
  margin: 0 5px 0 0;
}
.card-footer.page-footer {
  margin-top: -13px !important;
}
.mat-paginator-page-size-select {
  margin: 0px 4px 0 4px !important;
}
/***********************************************************************************************************************
zfilter Material Filter Hack
************************************************************************************************************************/
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3b3e6b !important;
}
.gridview-logic .mat-checkbox-anim-indeterminate-unchecked .mat-checkbox-background,
.gridview-logic .mat-checkbox-anim-checked-unchecked .mat-checkbox-background {
  background-color: #3b3e6b !important;
}
.gridview-logic .mat-checkbox-indeterminate .mat-checkbox-background {
  background: none !important;
}
.gridview-logic .mat-checkbox-indeterminate.mat-checkbox-checked .mat-checkbox-background {
  background: none !important;
}
.gridview-logic .mat-checkbox-indeterminate .mat-checkbox-mixedmark {
  display: none;
}
.gridview-logic .mat-checkbox-indeterminate.mat-checkbox-checked .mat-checkbox-mixedmark {
  opacity: 0;
}
.gridview-logic .mat-checkbox-anim-checked-unchecked .mat-checkbox-mixedmark,
.gridview-logic .mat-checkbox-anim-indeterminate-unchecked .mat-checkbox-mixedmark {
  height: 2px;
  opacity: 1;
  transform: scaleX(1) rotate(0);
}
.gridview-logic
  .mat-checkbox-checked.mat-checkbox-anim-indeterminate-unchecked
  .mat-checkbox-mixedmark {
  opacity: 0;
}

/***********************************************************************************************************************
zfilter Material Dialog
************************************************************************************************************************/
.dialog-newleague-logic {
  min-width: 300px;
}
.mat-dialog-title {
  margin: 0 !important;
}
.mat-dialog-description {
  margin: 0 0 25px;
}
/***********************************************************************************************************************
zfilter Filter Instructions
************************************************************************************************************************/
.card-info {
  position: absolute;
  float: right;
  right: 14px;
  top: 14px;
  cursor: pointer;
}
.card-info .mat-icon {
  margin-right: 0;
  color: #3b3e6b;
  line-height: 25px !important;
}
.filter-instructions-logic {
  padding: 10px;
}
.filter-instructions-label {
  font-weight: bold;
  color: #3b3e6b;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: -3px;
}
.filter-instructions-content {
  font-size: 12px;
}
.filter-instructions-section h4 {
  font-weight: bold;
  margin: 5px 0 0px;
}
/***********************************************************************************************************************
zfilter Gradients
************************************************************************************************************************/
.jumpmenubg {
  background: #333467;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #333467),
    color-stop(44%, #41326b),
    color-stop(100%, #1f2b58)
  );
  background: linear-gradient(to bottom, #333467 0, #41326b 44%, #1f2b58 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#333467', endColorstr='#1f2b58',GradientType=0 );
}
.tili-inputbox {
  background: #ffffff !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ffffff),
    color-stop(100%, #e0e0e0)
  ) !important;
  background: linear-gradient(to bottom, #ffffff 0, #e0e0e0 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#e0e0e0',GradientType=0 ) !important;
}
.btn-plb {
  background: #323366 none repeat scroll 0 0 !important;
  border-color: #141548 !important;
  float: right;
}
#ctl00_loginboxplace_ddlLeague {
  float: left;
  width: 170px;
}
.card-playersignup .leaderboard-season {
  margin-top: -20px;
}
.player-signup-select {
  display: table;
  margin: 20px 0;
  width: 100%;
}

/***********************************************************************************************************************
zfilter Loader Styling
************************************************************************************************************************/

.slds-spinner_container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9050;
  background-color: rgba(255, 255, 255, 0.75);
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease, visibility 0s;
  transition-delay: 0s, 0.3s;
  height: 100%;
  width: 100%;
}

.slds-spinner_container.is-hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease, visibility 0s;
  transition-delay: 0s, 0s;
}

.slds-spinner_container.hide {
  display: none;
}

.slds-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9051;
  transform: translate(-50%, -50%) rotate(90deg);
}

.slds-spinner_inline {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  transform: none;
}

.slds-spinner,
.slds-spinner__dot-a,
.slds-spinner__dot-b {
  transform-origin: 50% 50%;
}

.slds-spinner__dot-a,
.slds-spinner__dot-b {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.slds-spinner:before,
.slds-spinner:after,
.slds-spinner__dot-a:before,
.slds-spinner__dot-b:before,
.slds-spinner__dot-a:after,
.slds-spinner__dot-b:after {
  position: absolute;
  content: '';
  background: #b0adab;
  border-radius: 50%;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
}

.slds-spinner__dot-a {
  transform: rotate(60deg) translateY(0);
}

.slds-spinner__dot-b {
  transform: rotate(120deg) translateY(0);
}

.slds-spinner:before {
  animation-delay: -83.33333ms;
}

.slds-spinner__dot-a:before {
  animation-delay: 83.33333ms;
}

.slds-spinner__dot-b:before {
  animation-delay: 250ms;
}

.slds-spinner:after {
  animation-delay: 416.66667ms;
}

.slds-spinner__dot-a:after {
  animation-delay: 583.33333ms;
}

.slds-spinner__dot-b:after {
  animation-delay: 750ms;
}

.slds-spinner_delayed:before {
  animation-delay: 216.66667ms;
}

.slds-spinner_delayed .slds-spinner__dot-a:before {
  animation-delay: 383.33333ms;
}

.slds-spinner_delayed .slds-spinner__dot-b:before {
  animation-delay: 550ms;
}

.slds-spinner_delayed:after {
  animation-delay: 716.66667ms;
}

.slds-spinner_delayed .slds-spinner__dot-a:after {
  animation-delay: 883.33333ms;
}

.slds-spinner_delayed .slds-spinner__dot-b:after {
  animation-delay: 1050ms;
}

.slds-spinner_inverse.slds-spinner:before,
.slds-spinner_inverse.slds-spinner:after,
.slds-spinner_inverse .slds-spinner__dot-a:before,
.slds-spinner_inverse .slds-spinner__dot-b:before,
.slds-spinner_inverse .slds-spinner__dot-a:after,
.slds-spinner_inverse .slds-spinner__dot-b:after,
.slds-spinner--inverse.slds-spinner:before,
.slds-spinner--inverse.slds-spinner:after,
.slds-spinner--inverse .slds-spinner__dot-a:before,
.slds-spinner--inverse .slds-spinner__dot-b:before,
.slds-spinner--inverse .slds-spinner__dot-a:after,
.slds-spinner--inverse .slds-spinner__dot-b:after {
  background-color: white;
}

.slds-spinner_xx-small,
.slds-spinner--xx-small {
  width: 0.5rem;
}

.slds-spinner_xx-small.slds-spinner_inline,
.slds-spinner--xx-small.slds-spinner_inline {
  height: 0.5rem;
}

.slds-spinner_xx-small.slds-spinner:before,
.slds-spinner_xx-small.slds-spinner:after,
.slds-spinner_xx-small .slds-spinner__dot-a:before,
.slds-spinner_xx-small .slds-spinner__dot-b:before,
.slds-spinner_xx-small .slds-spinner__dot-a:after,
.slds-spinner_xx-small .slds-spinner__dot-b:after,
.slds-spinner--xx-small.slds-spinner:before,
.slds-spinner--xx-small.slds-spinner:after,
.slds-spinner--xx-small .slds-spinner__dot-a:before,
.slds-spinner--xx-small .slds-spinner__dot-b:before,
.slds-spinner--xx-small .slds-spinner__dot-a:after,
.slds-spinner--xx-small .slds-spinner__dot-b:after {
  width: 0.125rem;
  height: 0.125rem;
}

.slds-spinner_xx-small.slds-spinner:before,
.slds-spinner_xx-small .slds-spinner__dot-a:before,
.slds-spinner_xx-small .slds-spinner__dot-b:before,
.slds-spinner--xx-small.slds-spinner:before,
.slds-spinner--xx-small .slds-spinner__dot-a:before,
.slds-spinner--xx-small .slds-spinner__dot-b:before {
  top: -0.0625rem;
  left: -0.0625rem;
  animation-name: dotsBounceBefore-extraExtraSmall;
}

.slds-spinner_xx-small.slds-spinner:after,
.slds-spinner_xx-small .slds-spinner__dot-a:after,
.slds-spinner_xx-small .slds-spinner__dot-b:after,
.slds-spinner--xx-small.slds-spinner:after,
.slds-spinner--xx-small .slds-spinner__dot-a:after,
.slds-spinner--xx-small .slds-spinner__dot-b:after {
  top: -0.0625rem;
  right: -0.0625rem;
  animation-name: dotsBounceAfter-extraExtraSmall;
}

@keyframes dotsBounceBefore-extraExtraSmall {
  60% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.275, 0.0425, 0.34, 0.265);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 0.555, 0.35, 0.715);
    transform: translateX(-0.125rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes dotsBounceAfter-extraExtraSmall {
  60% {
    animation-timing-function: cubic-bezier(0.275, 0.0425, 0.34, 0.265);
    transform: translateX(0);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 0.555, 0.35, 0.715);
    transform: translateX(0.125rem);
  }
  100% {
    transform: translateX(0);
  }
}

.slds-spinner_x-small,
.slds-spinner--x-small {
  width: 1rem;
}

.slds-spinner_x-small.slds-spinner_inline,
.slds-spinner--x-small.slds-spinner_inline {
  height: 1rem;
}

.slds-spinner_x-small.slds-spinner:before,
.slds-spinner_x-small.slds-spinner:after,
.slds-spinner_x-small .slds-spinner__dot-a:before,
.slds-spinner_x-small .slds-spinner__dot-b:before,
.slds-spinner_x-small .slds-spinner__dot-a:after,
.slds-spinner_x-small .slds-spinner__dot-b:after,
.slds-spinner--x-small.slds-spinner:before,
.slds-spinner--x-small.slds-spinner:after,
.slds-spinner--x-small .slds-spinner__dot-a:before,
.slds-spinner--x-small .slds-spinner__dot-b:before,
.slds-spinner--x-small .slds-spinner__dot-a:after,
.slds-spinner--x-small .slds-spinner__dot-b:after {
  width: 0.25rem;
  height: 0.25rem;
}

.slds-spinner_x-small.slds-spinner:before,
.slds-spinner_x-small .slds-spinner__dot-a:before,
.slds-spinner_x-small .slds-spinner__dot-b:before,
.slds-spinner--x-small.slds-spinner:before,
.slds-spinner--x-small .slds-spinner__dot-a:before,
.slds-spinner--x-small .slds-spinner__dot-b:before {
  top: -0.125rem;
  left: -0.125rem;
  animation-name: dotsBounceBefore-extraSmall;
}

.slds-spinner_x-small.slds-spinner:after,
.slds-spinner_x-small .slds-spinner__dot-a:after,
.slds-spinner_x-small .slds-spinner__dot-b:after,
.slds-spinner--x-small.slds-spinner:after,
.slds-spinner--x-small .slds-spinner__dot-a:after,
.slds-spinner--x-small .slds-spinner__dot-b:after {
  top: -0.125rem;
  right: -0.125rem;
  animation-name: dotsBounceAfter-extraSmall;
}

@keyframes dotsBounceBefore-extraSmall {
  60% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    transform: translateX(-0.25rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes dotsBounceAfter-extraSmall {
  60% {
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transform: translateX(0);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    transform: translateX(0.25rem);
  }
  100% {
    transform: translateX(0);
  }
}

.slds-spinner_small,
.slds-spinner--small {
  width: 1.25rem;
}

.slds-spinner_small.slds-spinner_inline,
.slds-spinner--small.slds-spinner_inline {
  height: 1.25rem;
}

.slds-spinner_small.slds-spinner:before,
.slds-spinner_small.slds-spinner:after,
.slds-spinner_small .slds-spinner__dot-a:before,
.slds-spinner_small .slds-spinner__dot-b:before,
.slds-spinner_small .slds-spinner__dot-a:after,
.slds-spinner_small .slds-spinner__dot-b:after,
.slds-spinner--small.slds-spinner:before,
.slds-spinner--small.slds-spinner:after,
.slds-spinner--small .slds-spinner__dot-a:before,
.slds-spinner--small .slds-spinner__dot-b:before,
.slds-spinner--small .slds-spinner__dot-a:after,
.slds-spinner--small .slds-spinner__dot-b:after {
  width: 0.25rem;
  height: 0.25rem;
}

.slds-spinner_small.slds-spinner:before,
.slds-spinner_small .slds-spinner__dot-a:before,
.slds-spinner_small .slds-spinner__dot-b:before,
.slds-spinner--small.slds-spinner:before,
.slds-spinner--small .slds-spinner__dot-a:before,
.slds-spinner--small .slds-spinner__dot-b:before {
  top: -0.125rem;
  left: -0.125rem;
  animation-name: dotsBounceBefore-small;
}

.slds-spinner_small.slds-spinner:after,
.slds-spinner_small .slds-spinner__dot-a:after,
.slds-spinner_small .slds-spinner__dot-b:after,
.slds-spinner--small.slds-spinner:after,
.slds-spinner--small .slds-spinner__dot-a:after,
.slds-spinner--small .slds-spinner__dot-b:after {
  top: -0.125rem;
  right: -0.125rem;
  animation-name: dotsBounceAfter-small;
}

@keyframes dotsBounceBefore-small {
  60% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes dotsBounceAfter-small {
  60% {
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transform: translateX(0);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    transform: translateX(0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}

.slds-spinner_medium,
.slds-spinner--medium {
  width: 2rem;
}

.slds-spinner_medium.slds-spinner_inline,
.slds-spinner--medium.slds-spinner_inline {
  height: 2rem;
}

.slds-spinner_medium.slds-spinner:before,
.slds-spinner_medium.slds-spinner:after,
.slds-spinner_medium .slds-spinner__dot-a:before,
.slds-spinner_medium .slds-spinner__dot-b:before,
.slds-spinner_medium .slds-spinner__dot-a:after,
.slds-spinner_medium .slds-spinner__dot-b:after,
.slds-spinner--medium.slds-spinner:before,
.slds-spinner--medium.slds-spinner:after,
.slds-spinner--medium .slds-spinner__dot-a:before,
.slds-spinner--medium .slds-spinner__dot-b:before,
.slds-spinner--medium .slds-spinner__dot-a:after,
.slds-spinner--medium .slds-spinner__dot-b:after {
  width: 0.5rem;
  height: 0.5rem;
}

.slds-spinner_medium.slds-spinner:before,
.slds-spinner_medium .slds-spinner__dot-a:before,
.slds-spinner_medium .slds-spinner__dot-b:before,
.slds-spinner--medium.slds-spinner:before,
.slds-spinner--medium .slds-spinner__dot-a:before,
.slds-spinner--medium .slds-spinner__dot-b:before {
  animation-name: dotsBounceBefore-medium;
  top: -0.25rem;
  left: -0.25rem;
}

.slds-spinner_medium.slds-spinner:after,
.slds-spinner_medium .slds-spinner__dot-a:after,
.slds-spinner_medium .slds-spinner__dot-b:after,
.slds-spinner--medium.slds-spinner:after,
.slds-spinner--medium .slds-spinner__dot-a:after,
.slds-spinner--medium .slds-spinner__dot-b:after {
  animation-name: dotsBounceAfter-medium;
  top: -0.25rem;
  right: -0.25rem;
}

@keyframes dotsBounceBefore-medium {
  60% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    transform: translateX(-0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes dotsBounceAfter-medium {
  60% {
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transform: translateX(0);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}

.slds-spinner_large,
.slds-spinner--large {
  width: 2.75rem;
}

.slds-spinner_large.slds-spinner_inline,
.slds-spinner--large.slds-spinner_inline {
  height: 2.75rem;
}

.slds-spinner_large.slds-spinner:before,
.slds-spinner_large.slds-spinner:after,
.slds-spinner_large .slds-spinner__dot-a:before,
.slds-spinner_large .slds-spinner__dot-b:before,
.slds-spinner_large .slds-spinner__dot-a:after,
.slds-spinner_large .slds-spinner__dot-b:after,
.slds-spinner--large.slds-spinner:before,
.slds-spinner--large.slds-spinner:after,
.slds-spinner--large .slds-spinner__dot-a:before,
.slds-spinner--large .slds-spinner__dot-b:before,
.slds-spinner--large .slds-spinner__dot-a:after,
.slds-spinner--large .slds-spinner__dot-b:after {
  width: 0.625rem;
  height: 0.625rem;
}

.slds-spinner_large.slds-spinner:before,
.slds-spinner_large .slds-spinner__dot-a:before,
.slds-spinner_large .slds-spinner__dot-b:before,
.slds-spinner--large.slds-spinner:before,
.slds-spinner--large .slds-spinner__dot-a:before,
.slds-spinner--large .slds-spinner__dot-b:before {
  animation-name: dotsBounceBefore-medium;
  top: -0.3125rem;
  left: -0.3125rem;
}

.slds-spinner_large.slds-spinner:after,
.slds-spinner_large .slds-spinner__dot-a:after,
.slds-spinner_large .slds-spinner__dot-b:after,
.slds-spinner--large.slds-spinner:after,
.slds-spinner--large .slds-spinner__dot-a:after,
.slds-spinner--large .slds-spinner__dot-b:after {
  animation-name: dotsBounceAfter-medium;
  top: -0.3125rem;
  right: -0.3125rem;
}

@keyframes dotsBounceBefore-large {
  60% {
    transform: translateX(0);
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    transform: translateX(-0.75rem);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes dotsBounceAfter-large {
  60% {
    animation-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
    transform: translateX(0);
  }
  80% {
    animation-timing-function: cubic-bezier(0, 1.11, 0.7, 1.43);
    transform: translateX(0.75rem);
  }
  100% {
    transform: translateX(0);
  }
}

/***********************************************************************************************************************
zfilter Classic Leaderboards
************************************************************************************************************************/
.lbGridDefault tr:first-child th {
  border-top: none;
}
.lbGridDefault tr:last-child td {
  border-bottom: none;
}

.lbGridDefault table {
  border: solid 1px 1px solid #e5e5e5;
}
.lbGridDefault td {
  padding: 4px 5px 4px 5px;
}
.lbGridDefault th {
  white-space: nowrap;
  padding: 2px 3px 2px 3px;
}
.lbGridDefault th a {
  color: #66665c;
}
.lbGridDefault th a:hover {
  color: #990000;
}
.lbGridDefault th,
.lbGridDefault td {
  border: 1px solid #e5e5e5;
}
.lbeditGridDefault th,
.lbeditGridDefault td {
  border: 1px solid #e5e5e5;
}
.classic-header-padding {
  padding: 15px 15px;
}

.classic-header {
  background: #d7d7ce;
  margin: -15px -10px -25px;
}

.classic-leaderboard .card-block select {
  border-color: #ababab !important;
}
.classic-leaderboard .card-block input {
  border-color: #ababab !important;
  padding: 6px;
  border-radius: 2px !important;
}
.classic-leaderboard .card-footer {
  background: #d7d7ce;
}
.lbHeader th {
  text-align: right;
  padding: 0 5px 0 0 !important;
}
/******************************
zfilter Leaderboards Sizes
******************************/
.grid-mainevent th:nth-child(1) {
  width: 10%;
  text-align: center;
}
.grid-mainevent th:nth-child(2) {
  width: 90%;
}
.grid-mainevent td:nth-child(1) {
  text-align: center;
}
.grid-mainevent td:nth-child(2) {
}
.grid-membership th:nth-child(1) {
  width: 70%;
}
.grid-membership th:nth-child(2) {
  width: 30%;
  text-align: center;
}
.grid-membership td:nth-child(1) {
}
.grid-membership td:nth-child(2) {
  text-align: center;
}

.grid-classiclb th:nth-child(1) {
  width: 10%;
  text-align: center;
}
.grid-classiclb th:nth-child(2) {
  width: 60%;
}
.grid-classiclb th:nth-child(3) {
  width: 20%;
  text-align: center;
}
.grid-classiclb th:nth-child(4) {
  width: 10%;
  text-align: center;
}
.grid-classiclb tr td:nth-child(1) {
  text-align: center;
}
.grid-classiclb tr td:nth-child(3) {
  text-align: center;
}

/***********************************************************************************************************************
zfilter Leaderboards
************************************************************************************************************************/
.player-avatar {
  float: left;
  margin-right: 10px;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid #fff;
  border-radius: 3px;
  box-shadow: 0 0 7px -2px #000;
}
.player-info-name {
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
  padding-top: 10px;
}
.player-info-name a {
  color: #3b3e6b;
}
.player-info-venue {
  font-size: 16px;
}
.player-logic {
  display: table;
  width: 100%;
  padding: 15px 0 15px;
  border-bottom: 1px solid #e2e2e2;
}
.leaderboard-notice {
  background: #ffd485;
  color: #2a2c66;
  text-align: center;
  padding: 5px;
  font-weight: 700;
}
.totem-style {
  display: table;
  float: left;
  font-size: 13px;
  margin: 0 2px;
}
.leaderboard-profile-edit {
  display: table;
  float: left;
  font-family: gudea;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
}
.leaderboard-recent {
  cursor: pointer;
  float: left;
  font-family: gudea;
  font-size: 13px;
  font-weight: normal;
  line-height: 20px;
}
.leaderboard-header-right {
  width: 60px !important;
  float: right;
}
.leaderboard-header-right {
  display: table;
  float: right;
  width: 50px;
}
.leaderboardquartz-lbMainRow > td {
  text-align: center;
}
.custom-profile-grid th,
.custom-profile-grid td {
  border-width: 1px !important;
  border-style: solid !important;
}

.leaderboardquartz th {
  text-align: center;
}
.leaderboardquartz-lbGridDefault tr:nth-child(2n + 1) {
  background: none repeat scroll 0 0 #fff;
}
.leaderboardquartz-lbMainRow a {
  color: blue;
  text-decoration: underline !important;
}
.leaderboardquartz-lbMainRow a:hover {
  text-decoration: none !important;
}
.leaderboardquartz-lbMainRow:hover {
  background: none repeat scroll 0 0 #d3d3d3 !important;
}
.leaderboardquartz-lbMainRow {
  background: none repeat scroll 0 0 #f2f2f2;
  border-bottom: 1px solid #d3d3d3;
  -webkit-box-shadow: 0 0 41px -34px #1a1a1a inset;
  box-shadow: 0 0 41px -34px #1a1a1a inset;
  color: #000;
}
.leaderboardquartz-lbGridDefault {
  border: medium none;
}
.leaderboardquartz-lbGridDefault a {
  display: table;
  font-family: Arial;
  font-size: 13px;
  font-weight: normal !important;
  margin: 7px 0 !important;
}
#grdleaderboardquartz th {
  background: none repeat scroll 0 0 #1a1a1a !important;
  color: #ffffff !important;
  font-weight: normal;
}
#grdLeaderBoardQuartz th a {
  color: #ffffff !important;
  display: inline;
  text-decoration: none;
}
.card-leaderboard {
  margin-bottom: 0 !important;
  max-width: 750px;
  padding: 0;
  box-shadow: none !important;
}
.card-leaderboard-details {
  max-width: 750px;
  padding: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  margin: 10px auto 0;
}
.card-leaderboard-details .leaderboard-card-head {
  color: #fff;
  padding: 17px 15px 0px !important;
}
.leaderboard-card-head .mat-form-field-label {
    color: rgba(255, 255, 255, 0.8) !important;
}
.quartz-avatar .mat-form-field-label {
    color: rgba(0,0,0,0.7) !important;
}
.leaderboard-card-leaguename {
  font-size: 24px;
  margin: 0 auto -5px;
  display: table;
  line-height: 34px;
}
#grdLeaderBoardQuartz {
  width: 100%;
}
.card-leaderboard .gridview-logic {
  margin: 0 -20px !important;
}
.alert-leaderboard {
  max-width: 750px;
}
.leaderboard-header {
  font-weight: bold;
  text-align: center;
  padding: 7px 0px;
  cursor: pointer;
}
.leaderboard-header a {
  font-weight: bold !important;
  text-align: center !important;
}
.leaderboard-header.header-left {
  text-align: left !important;
}
.card .leaderboard-gridview-logic {
  border: medium none !important;
  margin: -15px -8px 15px;
}
.leaderboard-showall {
  display: table;
  float: right;
  margin: 12px 10px 4px;
  position: relative;
}
.leaderboard-season {
  margin-top: 3px;
}
.leaderboard-season select {
  width: auto !important;
}
.launch-hosted {
  display: table;
  font-size: 20px !important;
  margin: 30px auto;
}
.action-launchhosted-button {
  margin: 10px auto;
  display: table;
  font-size: 18px;
}
.leaderboard-venue {
  float: left;
  font-size: 14px !important;
  margin: 8px 5px 0 0;
}
#btnVenueDetail {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  display: table;
  float: right;
  font-family: gudea;
  font-size: 14px;
  margin-top: 4px;
  width: auto;
}
.venue-filter {
  margin-bottom: 8px !important;
}
.leaderboard-card-head {
  background: #212366;
  background: radial-gradient(ellipse at center, #212366 0, #323366 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#212366', endColorstr='#323366',GradientType=1 );
  color: #fff;
  font-family: alfa;
  padding: 30px 0 !important;
  text-align: center;
  font-weight: normal !important;
  border: medium none !important;
}
.leaderboard-card-head-type {
  font-family: gudea;
  font-size: 11px;
  margin-top: 8px;
  opacity: 0.5;
}
.leaderboard-card-head-date {
  font-family: gudea;
  margin-top: 3px;
}
.leaderboard-panel {
  margin: 0;
  padding-top: 15px;
}
.leaderboard-panel select {
  width: 78% !important;
}
.leaderboard-card-head select {
  color: #000;
  margin-top: 7px;
}
.leaderboard-btm-switches {
  margin-top: 10px;
}
.leaderboard-pwrby {
  float: left;
  font-family: gudea;
  font-size: 12px;
  margin: 0 0 0 -5px;
}
.leaderboard-brand-logic {
  display: table;
  margin: 15px auto 14px;
}
.leaderboard-footer {
  display: table;
  margin-left: 57px;
  max-width: 255px;
}
.leaderboard-footer img {
  width: 100%;
}
.paginated-style {
  color: #323366;
  font-size: 17px;
  font-weight: 300;
}
.card-leaderboard .leaderboard-profile-edit i {
  margin-right: 5px;
}
.leaderboardquartz-lbMainRow td:nth-child(1) {
  font-weight: bold !important;
}
.leaderboardquartz-lbMainRow td:nth-child(3) {
  font-weight: bold !important;
}
.card-leaderboard .card-footer {
  border: medium none;
}
.leaderboard-body .ad-card {
  margin-bottom: 10px;
}
.leaderboard-mat-panel {
  margin-bottom: 20px !important;
}
.leaderboard-mat-panel .mat-expansion-panel-body {
  padding: 0;
}
.card-leaderboard-details .pagination {
  position: relative;
}
.action-copyleaderboard-button {
  position: relative !important;
  top: 12px;
}
.action-viewleaderboard-button {
  position: relative !important;
  top: 12px;
}
.leaderboard-input {
  width: 91% !important;
}
.action-leaderboardvenue {
  display: block;
  position: relative;
  width: 40%;
  right: 0;
  float: right;
  top: 0px;
}
.tournament-leaderboard-editor td:nth-child(1) {
  width: 12%;
  text-align: center;
}
.tournament-leaderboard-editor td:nth-child(2) {
  width: 58%;
  text-align: center;
}
.tournament-leaderboard-editor td:nth-child(3) {
  width: 30%;
}
.card-leaderboard-details .card-block {
  padding-bottom: 0;
  margin-bottom: -2px;
  display: block;
}
.card.leaderboard-ad {
  -webkit-box-shadow: none;
  box-shadow: none;
  max-width: 750px;
  border-radius: 0;
  margin: 0 auto;
}
.card.leaderboard-ad .card-block {
  padding: 0 !important;
}
.card.leaderboard-ad .card-block .ad-card {
  padding: 10px !important;
  margin: 10px auto 0;
  background: #eee;
  border-radius: 0.5rem;
}
.grid-membership table tr:nth-child(1) td {
  padding: 0.75rem 0.75rem 0.75rem 14px !important;
}
.grid-membership table tbody tr:nth-child(1) {
  background: rgba(0, 0, 0, 0.05) !important;
}
.grid-mainevent table th {
  border: 1px solid #dee2e6;
}
.grid-mainevent table tr:nth-child(1) td {
  padding: 0.75rem 0.75rem 0.75rem 14px !important;
  border: 1px solid #dee2e6;
}
.grid-mainevent table tbody tr:nth-child(1) {
  background: rgba(0, 0, 0, 0.05) !important;
}
.grid-mainevent {
  margin-bottom: 7px !important;
}
.grid-membership {
  margin-bottom: -3px !important;
}
.leaderboard-loading {
  font-size: 75px;
  text-align: center;
  color: #3b3e6b;
  padding: 20px 0;
}
.card-block.recent-winners {
  padding: 0 15px;
}
.classic-leaderboard .leaderboard-gridview-logic {
  margin-top: 25px;
}

.avatar-block {
  width: 60px;
  height: 60px;
  overflow: hidden;
  float: left;
  border-radius: 3px;
  margin: 4px 10px 4px 0;
  background-size: contain !important;
  background-position: center center !important;
  -webkit-box-shadow: 0 0 13px -8px;
  box-shadow: 0 0 13px -8px;
  border: 1px solid #fff !important;
  background: #fff;
}
.quartz-avatar .leaderboard-details-btn {
  top: 46px;
}
.quartz-avatar .leaderboard-user-actions-logic {
  margin-top: 2px;
}
.avatar-block img {
  width: 100%;
  display: table;
}
.quartz-avatar td {
  line-height: 52px;
}
.quartz-avatar td a {
  display: list-item;
  font-size: 13px;
  font-weight: normal !important;
  margin: 17px 0 0 !important;
  background: #fff;
  border-radius: 5px;
  padding: 6px;
  -webkit-box-shadow: 0 0 14px -10px #000;
  box-shadow: 0 0 14px -10px #000;
  line-height: 21px;
  text-decoration: none !important;
  color: black;
  border: 1px solid rgba(142, 142, 142, 0.25) !important;
  text-align: left;
}
.quartz-avatar .leaderboard-card-head .mat-accordion {
  border-radius: 5px;
  overflow: hidden;
  display: table;
}
.quartz-avatar .mat-expansion-panel-header-title {
  font-weight: bold;
}

@media screen and (max-width: 360px) {
  .leaderboardquartz-lbGridDefault tbody tr th:nth-child(4) {
    display: none;
  }
  .leaderboardquartz-lbMainRow td:nth-child(4) {
    display: none;
  }
  .totem-style {
    display: none;
  }
  .leaderboard-recent {
    display: none;
  }
}
/***********************************************************************************************************************
zfilter Header
************************************************************************************************************************/
.login-logic {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
.headlogic {
  border-bottom: 1px solid #000000;
  position: relative;
  width: 100%;
  display: table;
  background: #323467;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #323467),
    color-stop(24%, #333367),
    color-stop(100%, #022042)
  );
  background: linear-gradient(to bottom, #323467 0, #333367 24%, #022042 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#323467', endColorstr='#022042',GradientType=0 );
}
#header {
  margin: auto;
  max-width: 1140px;
}
.headermenulogic {
  float: right;
}
.headermenubtn > input {
  padding: 3px 0;
  width: 16px;
}
.headermenubtn {
  background: none repeat scroll 0 0 #3b3e6b;
  border-radius: 0 0 3px 3px;
  color: #fff;
  float: left;
  margin-left: 5px;
  padding: 5px 10px;
}
.headermenubtn:hover {
  -ms-opacity: 0.8;
  opacity: 0.8;
}
.headermenubtn a {
  color: #fff;
  display: table;
  padding: 2px 0;
  text-decoration: none;
}
.headermenubtn i {
  display: table;
  float: left;
  font-size: 16px;
  line-height: 18px;
  padding: 0;
  width: 22px;
}
#logo {
  float: left;
}
#logo > img {
  width: 65%;
}
#header_graphic {
  height: 175px;
}
.quartz-main-header {
  background: #222768;
  background: -moz-linear-gradient(top, #222768 0%, #3b3e6b 100%);
  background: -webkit-linear-gradient(top, #222768 0%, #3b3e6b 100%);
  background: linear-gradient(to bottom, #222768 0%, #3b3e6b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222768', endColorstr='#3b3e6b',GradientType=0 );
  height: 45px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
}
.quartz-main-header::after {
  clear: both;
  content: '';
  display: table;
}
.quartz-logo {
  display: block;
  float: left;
  margin: 8px 0 0 7px;
}
.quartz-logo img {
  display: block;
  width: 220px;
}
.action-btn {
  margin-bottom: 10px;
}
.action-to-top-btn {
  position: fixed !important;
  right: 25px;
  background: #999999 !important;
}
.nav-is-visible .loginstring {
  display: table;
  float: left;
  margin-bottom: 3px;
  margin-right: 7px;
  padding-left: 0;
}
.logout-dropdown {
  background: #3b3e6b none repeat scroll 0 0 !important;
  margin-bottom: -5px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: 0 !important;
  padding: 0 !important;
}
.topbar-user {
  color: #fff;
  cursor: pointer;
  padding: 16px 16px 17px;
}
.topbar-system {
  background: #2c3136 none repeat scroll 0 0;
  color: #fff;
  cursor: pointer;
  padding: 16px 16px 17px;
}
.nav-is-visible .topbar-user {
  margin-top: -8px;
}
.nav-is-visible .systeminfo {
  display: table;
  position: absolute;
  right: 0 !important;
  text-align: right;
  top: 45px;
  z-index: 1;
}
.nav-is-visible .topbar-system {
  background: none !important;
}
.nav-is-visible #ctl00_logout {
  margin: -5px 0 13px;
  padding-left: 17px !important;
}
@media only screen and (min-width: 480px) {
  .quartz-logo img {
    width: 240px;
  }
  .quartz-logo {
    margin: 7px 0 0 7px;
  }
}
@media only screen and (min-width: 769px) {
  .quartz-main-header {
    position: fixed;
    height: 55px;
  }
  .quartz-logo {
    margin: 9px 0 0 9px;
  }
  .quartz-logo img {
    width: 300px;
  }
}
/***********************************************************************************************************************
zfilter Navigation
************************************************************************************************************************/
.plb-welcome-logic {
  background: #44488c;
  padding: 11px 0 8px;
  display: table;
  margin: 0;
  font-weight: bold;
  color: #fff;
  min-width: 280px;
}
.plb-welcome-logic.desktop {
  background: none;
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 0;
}
span.menu-sep {
  height: 4px;
  background: #d2d2d2;
  width: 100%;
  display: table;
  margin: 0;
}
.leagueoption-select {
  width: 92% !important;
  margin: 10px;
  background: none;
  border: none;
  font-size: 16px !important;
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
}
.mat-menu-content {
  padding: 0 !important;
}
.loggedin-label {
  clear: both;
  display: table;
  font-size: 10px;
  padding-left: 10px;
  margin-bottom: -2px;
  color: #b7baf3;
}
.loggedin-content {
  padding-left: 10px;
}
.user-menu-logic {
  display: table;
  float: right;
  background: #44488c;
  color: #fff;
  margin-left: 10px;
  height: 45px;
}
.mainMenu {
  height: 54px;
  width: 15px;
  padding: 0 !important;
  min-width: 50px !important;
}

.nav-menu-item button {
  width: 100%;
  text-align: left;
  color: #fff;
  font-weight: bold;
  padding: 7px 15px 5px;
  border-radius: 0;
  line-height: 40px;
}
.mat-sidenav {
  width: 100%;
  max-width: 260px;
  background: #1d2023;
  background: linear-gradient(135deg, #1d2023 0%, #2c3136 60%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d2023', endColorstr='#2c3136',GradientType=1 );
}
.quartz-side-nav.offCanvas {
  position: initial;
  padding: 0;
  visibility: visible;
  opacity: 1;
}
.offCanvas .mat-button-focus-overlay {
  background-color: none !important;
  opacity: 0 !important;
}
.mat-drawer-container {
  overflow: visible;
  height: 100vh;
}
.mat-drawer-content {
  overflow-y: scroll !important;
}
main.quartz-main-content {
  background: #dde0e2;
  background: -moz-linear-gradient(30deg, #dde0e2 0%, #f2f2f2 73%);
  background: -webkit-linear-gradient(30deg, #dde0e2 0%, #f2f2f2 73%);
  background: linear-gradient(30deg, #dde0e2 0%, #f2f2f2 73%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dde0e2', endColorstr='#f2f2f2',GradientType=1 );
  height: auto !important;
  min-height: 100vh;
}

.plb-welcome-logic .fas {
  position: absolute;
  right: 65px;
  margin-top: -5px;
}

/***********************************************************************************************************************
zfilter Sub Navigation
************************************************************************************************************************/
.btn {
  font-size: 1.5rem;
  padding: 4px 10px;
}
.btn-group button input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
}
.btn-group .dropdown-menu input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #000;
  font-size: 14px;
  margin: 0;
  padding: 7px 10px;
  text-align: left;
  width: 100% !important;
}
.dropdown-item:hover {
  background: #eee none repeat scroll 0 0 !important;
}
a.dropdown-item {
  font-size: 15px;
  padding: 6px 10px;
}
.dropdown-menu {
  min-width: 210px;
}
/***********************************************************************************************************************
zfilter Navigation
************************************************************************************************************************/
.exe-menu {
  background: rgb(39, 42, 47);
  z-index: -2 !important;
}
.exe-menu.active {
  background: none;
}
.nav-menu-item.active {
  -webkit-box-shadow: 5px 0px 24px -7px #000 inset;
  box-shadow: 5px 0px 24px -7px #000 inset;
}
.quartz-nav {
  /* top nav - not visible on mobile devices */
  display: none;
}
.quartz-side-nav .action-btn a:hover {
  background: #232653 none repeat scroll 0 0 !important;
}
@media only screen and (min-width: 768px) {
  .quartz-nav {
    display: block;
    float: right;
    height: 100%;
  }
}
.quartz-top-nav > li > a::before {
  /* reset style */
  display: none;
}
.quartz-top-nav > li a {
  padding: 1em 5% !important;
}
.quartz-top-nav img {
  /* avatar image */
  position: absolute;
  left: 1.8em;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: none;
}
@media only screen and (min-width: 768px) {
  .quartz-top-nav {
    height: 100%;
  }
  .quartz-top-nav a {
    display: block;
    font-size: 1.4rem;
    color: #ffffff;
  }
  .quartz-top-nav > li {
    display: table;
    float: left;
    height: 100%;
    margin: 0;
  }
  .quartz-top-nav > li:last-of-type {
    margin-right: 0;
  }
  .quartz-top-nav > li a {
    padding: 1em 0.6em !important;
  }
  .quartz-top-nav img {
    display: block;
  }
}
@media only screen and (min-width: 1170px) {
  .quartz-top-nav li:not(.has-children) a:hover {
    color: #3b3e6b;
  }
}
.quartz-nav-trigger {
  float: right;
  position: relative;
  display: block;
  width: 10px !important;
  height: 45px;
  margin-right: 0.9em;
  overflow: hidden;
  white-space: nowrap;
  color: transparent;
  margin: 0 !important;
  min-width: 50px !important;
  padding: 0 !important;
}
.quartz-nav-trigger span,
.quartz-nav-trigger span::before,
.quartz-nav-trigger span::after {
  position: absolute;
  display: inline-block;
  height: 3px;
  width: 30px;
  background: #ffffff;
}
.quartz-nav-trigger span {
  position: absolute;
  top: 50%;
  right: 9px;
  margin-top: -3px;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.quartz-nav-trigger span::before,
.quartz-nav-trigger span::after {
  content: '';
  right: 0;
  /* Force Hardware Acceleration in WebKit */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition: 0.2s;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.quartz-nav-trigger span::before {
  /* menu icon top line */
  top: -6px;
}
.quartz-nav-trigger span::after {
  /* menu icon bottom line */
  top: 6px;
}
.quartz-nav-trigger.nav-is-visible span {
  /* hide line in the center */
  background: rgba(255, 255, 255, 0);
}
.quartz-nav-trigger.nav-is-visible span::before,
.quartz-nav-trigger.nav-is-visible span::after {
  /* keep visible other 2 lines */
  background: white;
}
.quartz-nav-trigger.nav-is-visible span::before {
  -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
  transform: translateX(4px) translateY(-3px) rotate(45deg);
}
.quartz-nav-trigger.nav-is-visible span::after {
  -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
  transform: translateX(4px) translateY(2px) rotate(-45deg);
}
@media only screen and (min-width: 769px) {
  .quartz-nav-trigger {
    display: none !important;
  }
}
.quartz-search {
  position: relative;
  margin: 1.2em 5% 0.6em;
}
.quartz-search.is-hidden {
  opacity: 0;
}
.quartz-search::before {
  /* lens icon */
  content: '';
  position: absolute;
  left: 8px;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
}
.quartz-search input {
  padding-left: 32px;
  width: 100%;
  height: 36px;
  border: none;
  border-radius: 0.25em;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.quartz-search input:focus {
  outline: none;
}
@media only screen and (min-width: 768px) {
  .quartz-search {
    float: left;
    display: inline-block;
    width: 250px;
    height: 100%;
    margin: 0 0 0 2.5em;
  }
  .quartz-search.is-hidden {
    /* reset style */
    opacity: 1;
  }
  .quartz-search::before {
    background-position: 0 -16px;
    left: 1em;
  }
  .quartz-search form,
  .quartz-search input {
    height: 100%;
    width: 100%;
  }
  .quartz-search input {
    border: none;
    padding-left: 2.6em;
    border-radius: 0;
    background-color: #3e454c;
    border-left: 1px solid #51575d;
    color: #ffffff;
  }
  .quartz-search input::-webkit-input-placeholder {
    color: #777c81;
  }
  .quartz-search input::-moz-placeholder {
    color: #777c81;
  }
  .quartz-search input:-moz-placeholder {
    color: #777c81;
  }
  .quartz-search input:-ms-input-placeholder {
    color: #777c81;
  }
}
/* --------------------------------
Sidebar
-------------------------------- */
.quartz-side-nav {
  background: #1d2023;
  background: -moz-linear-gradient(-45deg, #1d2023 0%, #2c3136 60%);
  background: -webkit-linear-gradient(-45deg, #1d2023 0%, #2c3136 60%);
  background: linear-gradient(135deg, #1d2023 0%, #2c3136 60%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d2023', endColorstr='#2c3136',GradientType=1 );
  position: fixed;
  z-index: 1;
  left: 0;
  width: 100%;
  padding: 45px 0 0;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: opacity 0.2s 0, visibility 0 0.2s;
  transition: opacity 0.2s 0, visibility 0 0.2s;
}
.quartz-side-nav.nav-is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: opacity 0.2s 0, visibility 0 0;
  transition: opacity 0.2s 0, visibility 0 0;
  max-height: none;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.quartz-side-nav > ul:last-of-type {
  margin-bottom: 0 !important;
  margin-top: 18px;
  padding-bottom: 0;
  padding-top: 0;
}
.nav-is-visible ul:last-of-type {
  margin-top: -8px;
}
.quartz-side-nav .quartz-label,
.quartz-side-nav a {
  display: block;
  font-weight: bold;
  padding: 1em 5%;
}
.quartz-side-nav .quartz-label {
  text-transform: uppercase;
  font-weight: bold;
  color: #646a6f;
  font-size: 1rem;
  letter-spacing: 0.1em;
}
.quartz-side-nav a {
  position: relative;
  color: #ffffff;
  font-size: 1.4rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.quartz-side-nav ul.quartz-top-nav > li:last-of-type > a {
  border-bottom: none;
}
.quartz-side-nav > ul > li > a {
  padding-left: calc(5% + 24px);
  border-bottom: 1px solid #373d44;
}
.quartz-side-nav > ul > li.overview > a::before {
  background-position: -64px 0;
}
.quartz-side-nav > ul > li.notifications > a::before {
  background-position: -80px 0;
}
.quartz-side-nav > ul > li.comments > a::before {
  background-position: -48px 0;
}
.quartz-side-nav > ul > li.bookmarks > a::before {
  background-position: -32px 0;
}
.quartz-side-nav > ul > li.images > a::before {
  background-position: 0 0;
}
.quartz-side-nav > ul > li.users > a::before {
  background-position: -16px 0;
}
.quartz-side-nav .count {
  /* notification badge */
  position: absolute;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: calc(5% + 16px + 0.4em);
  padding: 0.2em 0.4em;
  background-color: #ff7e66;
  border-radius: 0.25em;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
}
.quartz-side-nav .action-btn a {
  display: block;
  margin: 0 1em;
  padding: 1em 0;
  background-color: #3b3e6b;
  border-radius: 0.25em;
  border: none;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.2);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.2);
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}
.quartz-side-nav .action-btn a::before {
  display: none;
}
.quartz-side-nav .active > button {
  -webkit-box-shadow: inset 3px 0 0 #009688;
  box-shadow: inset -12px 0 0 #009688;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 0;
}
.nav-menu-item.active > button {
  color: #009688;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.quartz-side-nav-overflow {
  height: 100%;
  overflow-y: scroll !important;
  padding-bottom: 30px;
}
.quartz-side-nav-actions {
  margin-top: 18px;
}
@media only screen and (min-width: 769px) {
  .quartz-side-nav {
    float: left;
    width: 180px;
    padding-top: 55px;
    visibility: visible;
    opacity: 1;
  }
  .quartz-side-nav.nav-is-visible {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .quartz-side-nav.is-fixed {
    position: fixed;
  }
  .quartz-side-nav > ul {
    /* reset style */
    padding: 0;
  }
  .quartz-side-nav .quartz-label {
    /* remove labels on minified version of the sidebar */
    display: none;
  }
  .quartz-side-nav a {
    font-size: 1.2rem;
    text-align: left;
  }
  .quartz-side-nav > ul > li > a {
    padding: calc(1rem + 20px) 0 1rem;
  }
  .quartz-side-nav > ul > li > a::before {
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 1.2rem;
  }
  .quartz-side-nav .action-btn a {
    margin: 1em 10% 0;
  }
  .quartz-side-nav .count {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    padding: 0;
    top: 2em;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    left: calc(50% + 5px);
    right: auto;
    color: transparent;
  }
}
@media only screen and (min-width: 1170px) {
  .quartz-side-nav a {
    font-size: 1.5rem;
    text-align: left;
  }
  .quartz-side-nav {
    width: 260px;
    height: 100vh;
  }
  .quartz-side-nav > ul {
    padding: 0;
  }
  .quartz-side-nav > ul > li:not(.action-btn):hover > a {
    background-color: #33383e;
  }
  .quartz-side-nav > ul > li > a {
    border-bottom: medium none;
    padding: 1em 1em 1em 20px;
    text-align: left;
  }
  .quartz-side-nav > ul > li > a::before {
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 18px;
  }
  .quartz-side-nav .quartz-label {
    display: block;
    padding: 1em 18px;
  }
  .quartz-side-nav .action-btn {
    text-align: left;
  }
  .action-btn {
    margin-bottom: 10px;
  }
  .quartz-side-nav .action-btn a {
    margin: 0 18px;
  }
  .no-touch .quartz-side-nav .action-btn a:hover {
    background-color: #1a93de;
  }
  .quartz-side-nav .count {
    /* reset style */
    color: #ffffff;
    height: auto;
    width: auto;
    border-radius: 0.25em;
    padding: 0.2em 0.4em;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 18px;
    left: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
.has-children ul {
  position: relative;
  width: 100%;
  display: none;
  background-color: #1c1f22;
}
.has-children > a::after {
  /* arrow icon */
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  right: 5%;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.has-children.selected > ul {
  display: block;
}
.nav-is-visible .has-children > ul {
  min-width: 200px;
  padding: 10px 0 10px 45px;
}
.has-children.selected > a::after {
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
@media only screen and (min-width: 768px) {
  .has-children {
    position: relative;
  }
  .has-children ul {
    position: absolute;
    top: 0;
    left: 100%;
    width: 160px;
    padding: 0;
    -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  .has-children ul a {
    text-align: left;
    border: none;
    padding: 1em;
  }
  .no-touch .has-children ul a:hover {
    color: #3b3e6b;
  }
  .has-children > a::after {
    display: none;
  }
  .quartz-side-nav .has-children.selected > a {
    /* focus state -> show sub pages */
    background-color: #33383e;
  }
  .quartz-top-nav .has-children {
    position: relative;
  }
  .quartz-top-nav .has-children > a {
    height: 100%;
    padding: 0 calc(1.8em + 22px) 0 calc(1.8em + 26px) !important;
    line-height: 55px;
  }
  .quartz-top-nav .has-children > a::after {
    display: block;
    right: 1.8em;
  }
  .quartz-top-nav .has-children ul {
    background-color: #1c1f22;
    width: 200px;
    top: 100%;
    right: 0;
    left: auto;
    -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  }
  .quartz-top-nav .has-children ul a {
    padding-left: 18px !important;
  }
}
@media only screen and (min-width: 1170px) {
  .has-children > ul {
    width: 100%;
    z-index: 1;
  }
  .has-children ul a {
    padding-left: 18px;
  }
  .has-children.active > ul {
    /* if the item is active, make the subnavigation visible */
    position: relative;
    display: block;
    /* reset style */
    left: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .no-touch .quartz-side-nav .has-children:hover > ul,
  .quartz-side-nav .has-children.hover > ul {
    /* show subnavigation on hover */
    display: block;
    -ms-opacity: 1;
    opacity: 1;
    visibility: visible;
  }
}
#navlogic {
  border-top: 1px solid #c5c5c5;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 100;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
#navlogic.stick {
  -webkit-box-shadow: 0 0 9px -4px #909090;
  box-shadow: 0 0 9px -4px #909090;
  position: fixed;
  top: 0;
  -webkit-transition: all 0.2s linear 0;
  transition: all 0.2s linear 0;
  z-index: 100000;
}
.Dashboard .secondmenulogic {
  display: none;
}
.secondmenulogic {
  background: rgba(255, 255, 255, 0.95) none repeat scroll 0 0;
  -webkit-box-shadow: 0 0 10px -7px #000;
  box-shadow: 0 0 10px -7px #000;
  color: #ffffff;
  float: left;
  position: fixed !important;
  width: 100%;
  z-index: 800;
  top: 55px;
  left: 260px;
  min-height: 50px;
}
.action-menu-button {
  background: #c3c3c3 !important;
}
.secondmenulogic .action-menu-button .mat-icon {
  color: #3b3e6b !important;
}
.action-new-button {
  background: #007bff !important;
}
.action-save-button {
  background: #4caf50 !important;
}
.action-saveclose-button {
  background: #009688 !important;
}
.secondmenulogic .action-reset-button .mat-icon {
  color: #3b3e6b !important;
}
.action-reset-button {
  background: #c3c3c3 !important;
  color: #3b3e6b !important;
}
.action-trial-button {
  background: #3b3e6b !important;
  color: #ffffff !important;
  font-weight: bold !important;
  font-size: 16px !important;
  margin: 20px auto !important;
  display: table !important;
}
.secondmenulogic button {
    background: #009688;
    min-width: auto;
    padding: 0 10px;
    margin-right: 4px;
}
.mat-button[disabled] {
  opacity: 0.5;
}
.secondmenupad {
  padding: 7px;
}
.secondmenulogic .mat-icon {
  font-size: 20px !important;
  line-height: 22px;
  color: #fff !important;
}
.secondmenuheader {
  background: none repeat scroll 0 0 #3d3d3d;
  border-radius: 3px;
  display: table;
  float: left;
  margin: 2px 5px 4px 0;
  padding: 4px 10px 0;
}
.secondmenulist {
  padding-left: 9px;
}
.secondmenulist > li {
  float: left;
  margin: 4px 4px 4px 0 !important;
}
.secondmenulist > li:hover {
  -ms-opacity: 0.9;
  opacity: 0.9;
}
.sub-actionbtn {
  border-radius: 2px;
}
.sub-sliderbtn {
  background: none repeat scroll 0 0 #00968e;
}
.sub-sliderbtn a {
  color: #fff;
  text-decoration: none;
}
#navigation {
  background: #45484d;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #45484d),
    color-stop(100%, #000000)
  );
  background: linear-gradient(to bottom, #45484d 0, #000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#45484d', endColorstr='#000000',GradientType=0 );
  height: 42px;
  letter-spacing: 0.01em;
  margin: auto;
  overflow: hidden;
}
#navigation ul {
  margin: auto;
  max-width: 1140px;
}
#navigation li {
  border-right: 1px solid #242424;
  color: #ffffff;
  display: block;
  float: left;
  font-family: arial;
  font-size: 16px;
  font-weight: normal;
  text-decoration: none;
  text-transform: uppercase;
}
#navigation li.last {
  border: 0;
}
#navigation li a {
  color: #ffffff;
  display: block;
  float: left;
  font-family: gudea;
  font-size: 16px;
  font-weight: normal;
  line-height: 30px;
  padding: 6px 10px;
  text-decoration: none;
}
a[href='#ctl00_ContentPlaceHolder1_mnuTabs_SkipLink'] {
  display: none;
}
#navigation li.green a {
  background-position: 0 -45px;
}
#navigation li.blue a {
  background-position: 0 -89px;
}
#navigation li a:hover,
#navigation li a.active {
  background: #545454;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #545454),
    color-stop(100%, #272727)
  );
  background: linear-gradient(to bottom, #545454 0, #272727 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#545454', endColorstr='#272727',GradientType=0 );
  -webkit-box-shadow: 0 -2px 6px -3px #000000 inset;
  box-shadow: 0 -2px 6px -3px #000000 inset;
  text-shadow: 1px 1px 3px #000000;
}
.menu-static-selected a {
  text-decoration: none;
}
.ctl00_ContentPlaceHolder1_mnuTabs tr td {
  width: 0 !important;
}
.menu-dash a::before {
  content: '';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 20px;
  font-weight: normal;
  height: 20px;
  margin: -5px 10px 0 0;
  width: 20px;
}
.nav-menu-item a:hover {
  background: #33383e none repeat scroll 0 0;
}
/***********************************************************************************************************************
zfilter Navigation Classes
************************************************************************************************************************/
.page-dashboard .menu-dash a,
.page-leaderboards .menu-leaderboards a,
.page-tournaments .menu-tournaments a,
.page-players .menu-players a,
.page-staff .menu-staff a,
.page-dealers .menu-dealers a,
.page-venues .menu-venues a,
.page-seasons .menu-seasons a,
.page-events .menu-events a,
.page-scoring .menu-scoring a,
.page-ads .menu-ads a,
.page-regions .menu-regions a,
.page-reports .menu-reports a {
  background: #1a1f25 none repeat scroll 0 0;
  -webkit-box-shadow: -2px 0 6px #000 inset, -13px 0 0 #323366 inset;
  box-shadow: -2px 0 6px #000 inset, -13px 0 0 #323366 inset;
}
/***********************************************************************************************************************
zfilter Navigation Icons
************************************************************************************************************************/
.menu-dash button::before {
  content: '\f3fd';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.menu-leaderboards button::before {
  content: '\e601';
  float: left;
  font-family: plbicons;
  font-size: 20px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.menu-tournaments button::before {
  content: '\f091';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.menu-players button::before {
  content: '\f007';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.menu-dealers button::before {
  content: '\e600';
  float: left;
  font-family: plbicons;
  font-size: 20px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  color: #686868;
}
.menu-staff button::before {
  content: '\f0c0';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  right: 2px;
}
.menu-venues button::before {
  content: '\f1ad';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.menu-seasons button::before {
  content: '\f08d';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  right: -1px;
}
.menu-events button::before {
  content: '\f02d';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.menu-scoring button::before {
  content: '\f005';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: relative;
  right: 2px;
}
.menu-ads button::before {
  content: '';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  right: 1px;
  position: relative;
}
.menu-regions button::before {
  content: '';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.menu-reports button::before {
  content: '';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0px 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.menu-leagues buttona::before {
  content: '';
  float: left;
  font-family: 'Font Awesome 5 Free';
  font-size: 17px;
  font-weight: 900;
  height: 20px;
  margin: 0 10px 0 0;
  width: 20px;
  color: #686868;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.nav-menu-item.active button:before {
  color: #009688;
}
/***********************************************************************************************************************
zfilter Content
************************************************************************************************************************/

.alerts-logic {
  position: relative !important;
  top: 40px;
  border-radius: 0 !important;
  left: -10px;
}
.alerts-logic .alert {
  position: relative !important;
  width: calc(100% + 20px) !important;
  border-radius: 0 !important;
  padding: 11px 8px 10px 55px !important;
}
.alerts-logic .alert::before {
  content: '\f06a';
  position: absolute;
  top: -1px;
  left: 0;
  font-family: Font awesome\5 Free;
  font-weight: 600;
  font-size: 21px;
  background: rgba(255, 255, 255, 0.25);
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 43px;
}
.alert .close {
  font-size: 30px;
  line-height: 22px;
}
.container,
.container-fluid {
  padding: 1rem 1rem 4rem !important;
}
#wrapper {
  background: none repeat scroll 0 0 #ffffff;
  margin: 0 auto;
  max-width: 1140px;
  padding: 0;
}
.static p {
  margin-top: 15px;
  margin-bottom: 15px;
}
#content {
  width: 530px;
  float: left;
}
.quartz-main-content .content-wrapper h1 {
  text-align: center;
  padding: 3em 0;
  font-size: 2rem;
}
.quartz-main-content::before {
  /* never visible - used to check MQ in jQuery */
  display: none;
  content: 'mobile';
}
.quartz-main-content .content-wrapper {
  padding-top: 45px;
}
@media only screen and (min-width: 769px) {
  .quartz-main-content .content-wrapper {
    margin-left: 180px;
    padding-top: 55px;
  }
  .quartz-main-content .content-wrapper h1 {
    padding: 4em 0;
    font-size: 3.2rem;
    font-weight: 300;
  }
  .quartz-main-content::before {
    content: 'tablet';
  }
}
@media only screen and (min-width: 1170px) {
  .quartz-main-content .content-wrapper {
    margin-left: 260px;
  }
  .quartz-main-content::before {
    content: 'desktop';
  }
}
#right_panel {
  width: 413px;
  float: right;
  position: relative;
}
.newAdBtn {
  margin: 10px 0;
}
#poker_cat {
  min-height: 188px;
  height: auto !important;
  height: 188px;
  background: url(/assets/images/poker.jpg) no-repeat 0 0;
  margin: 10px 0 20px 0;
}
#poker_cat ul {
  width: 300px;
  float: right;
}
#poker_cat li {
  float: left;
  width: 50%;
  padding: 10px 0;
}
#poker_membership {
  margin: 20px 0;
}
#poker_membership li {
  float: left;
  width: 49%;
  text-align: center;
  font-weight: bold;
}
#poker_membership a.free,
#poker_membership a.executive {
  background: url(/assets/images/poker_membership1.jpg) no-repeat 50% 0;
  display: block;
  padding-top: 110px;
  width: 190px;
  margin: 0 auto;
  text-decoration: none;
}
#poker_membership a.executive {
  background-image: url(/assets/images/poker_membership2.jpg);
}
#poker_membership a:hover {
  text-decoration: underline;
}
#poker_king {
  width: 499px;
  background: url(/assets/images/poker_king_box.jpg) no-repeat 0 0;
  padding-top: 23px;
  position: relative;
  color: #fff;
}
#poker_king .bottom {
  background: url(/assets/images/poker_king_box.jpg) no-repeat 0 100%;
  padding: 0 25px 30px 15px;
  min-height: 130px;
  height: auto !important;
  height: 130px;
}
#poker_king p {
  margin-left: 142px;
  line-height: 1.3em;
}
#poker_king .thumb {
  position: absolute;
}
#poker_king .author {
  display: block;
  margin-top: 5px;
  text-align: right;
}
#poker_king a {
  color: #fff;
}
#poker_king a.more {
  position: absolute;
  bottom: 2px;
  right: 25px;
  font-size: 0.8em;
}
#membership i {
  color: #666;
  font-size: 0.9em;
}
.signupbtn {
  background: #4caf50 none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
  font-family: arvoregular;
  font-size: 20px;
  height: 45px;
  line-height: 23px;
  margin-top: 15px !important;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.signupbtn:hover {
  background: none repeat scroll 0 0 #8bc34a;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.signup-leaguename {
  margin: -5px 0 10px;
}
.logoutUser {
  font-size: 12px;
  color: #000;
  float: right;
}
.sectionTab {
  border-style: solid solid none;
  border-color: #999;
  border-width: 1px 1px medium;
  padding: 3px 8px 3px 7px;
  background-color: #e8e8e8;
  margin-top: 26px;
  float: left;
}
.first {
  margin-top: 0;
}
.sectionBox {
  border: 1px solid #999;
  padding: 19px 19px 15px;
  clear: both;
  line-height: 24px;
}
div.paragraph {
  margin-bottom: 16px;
}
p.paragraph {
  margin-bottom: 10px;
}
.paypal_due {
  margin: 10px auto;
  max-width: 265px;
  text-align: center;
}
.paypal_due input {
  width: 100%;
  padding: 0 !important;
}
.downgrade_expl {
  display: block;
  margin: 0 0 30px;
}
.small {
  line-height: 16px;
}
.privileged {
  background-color: #f0f0f0;
}
#paymentStatusesTable tr td,
#paymentAmountTable tr td {
  padding: 6px !important;
}
#paymentStatusesTable tr td label,
#paymentAmountTable tr td label {
  padding-left: 5px;
  padding-right: 5px;
}
#features_table {
  width: 700px;
  border: none;
}
#features_table td {
  padding: 5px;
  margin: 1px;
  border: 1px solid #cccccc;
}
.sectionBox div.paragraph label {
  margin-left: 10px;
}
.textAmt {
  width: 60px;
}
.sectionBox div.paragraph a {
  font-weight: bold;
  text-decoration: none;
}
.sectionBox div.paragraph a:hover {
  text-decoration: underline;
}
#innerContent #editStaffRoles td {
  padding: 5px;
}
#editStaffRoles tbody tr td:nth-child(2) {
  display: none;
}
#editStaffRoles td label {
  margin-left: 5px;
}
#editStaffRoles td input {
  vertical-align: middle;
}
#ctl00_ContentPlaceHolder1_rptRoleList_ctl00_roleRow .role_border:nth-child(1) {
  border: 1px solid #cccccc;
}
.group-header {
  font-weight: bold;
  margin: 0;
  position: relative;
  top: 0;
}

#tourneyLeft {
  float: left;
  width: 500px;
}
#tourneyRight {
  float: left;
  width: 390px;
  margin-left: 38px;
}
.tourneySection {
  clear: both;
}
.tourneySectionHeader {
  color: #94aab9;
  font-weight: bold;
  float: left;
  margin-bottom: 10px;
}
#tourneyConfigTable tr td {
  padding-right: 5px;
  padding-bottom: 5px;
}
.sectionBox .spacedCell td {
  padding: 5px !important;
}
.boldLabel {
  font-weight: bold;
  text-align: right;
}
.details {
  float: left;
  margin-left: 8px;
}
.extra_leftspace {
  margin-left: 20px;
}
.small_leftspace input {
  margin-left: 8px;
  vertical-align: middle;
}
.card.signupcard .card-header {
  background: #4caf50 none repeat scroll 0 0 !important;
  border: none;
}
#gray_box {
  margin-top: 25px;
  background: #e8e8e8;
  border: 1px solid #d7d7ce;
  padding: 5px;
  position: relative;
}
#gray_box .arrow {
  background: url(/assets/images/arrow_top.gif) no-repeat 0 0;
  width: 25px;
  height: 20px;
  display: block;
  position: absolute;
  top: -20px;
  left: 130px;
}
#frame_box {
  height: 600px;
  padding: 10px;
  background: #fff;
  overflow: auto;
  margin: 10px;
}
#ctl00_ContentPlaceHolder1_ErrorMsg,
#ctl00_ContentPlaceHolder1_proFeaturesMessage_ErrorMsg,
#ctl00_lblInactiveNotice,
#ctl00_ContentPlaceHolder1_lblSystemMessage {
  color: Red;
  display: block;
  font-family: arial;
  font-size: 16px;
  font-weight: normal;
}
.error {
  color: red;
}
.bold {
  font-weight: bold;
}
#ctl00_lblInactiveNotice,
#ctl00_ContentPlaceHolder1_lblSystemMessage {
  margin: 10px 0 0 20px;
}
/***********************************************************************************************************************
zfilter Privacy
************************************************************************************************************************/

/***********************************************************************************************************************
zfilter Footer
************************************************************************************************************************/
.footer-section-logic {
  background: #eee none repeat scroll 0 0;
  height: 45px;
  margin-top: 150px;
}
.footerlogic {
  background: #323467;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #323467),
    color-stop(24%, #333367),
    color-stop(100%, #022042)
  );
  background: linear-gradient(to bottom, #323467 0, #333367 24%, #022042 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#323467', endColorstr='#022042',GradientType=0 );
  display: table;
  float: left;
  margin-top: 300px;
  width: 100%;
}
#footer {
  color: #ffffff;
  margin: 20px;
  text-align: right;
}
#footer a {
  color: #fff;
  text-decoration: none;
}
#footer a:hover,
#footer a.active {
  text-decoration: underline;
}
/********************* classes for differentiation of box and heading ********************/
.arrow_head {
  background: url(/assets/images/arrow_head.gif) no-repeat 100% 50%;
  height: 28px;
  overflow: hidden;
  margin: 10px 0;
}
.arrow_head h1,
.arrow_head h2 {
  border: 1px solid #d7d7ce;
  background: #e8e8e8;
  float: left;
  padding: 0 5px;
  margin: 0;
  height: 26px;
  line-height: 26px;
  overflow: hidden;
  font-size: 1.5em;
}
.footer_banner {
  text-align: center;
  margin: 20px;
}
.submit {
  background: none repeat scroll 0 0 #0052cf !important;
  border: 0 none;
  border-radius: 3px;
  color: #fff;
  display: table;
  margin: 5px 0 0;
  padding: 7px 30px;
}
.sub-submit {
  background: none repeat scroll 0 0 #df821f;
  border: medium none;
  border-radius: 2px;
  color: #fff;
  padding: 8px 5px 7px;
}
.sub-actionbtn > input {
  background: none repeat scroll 0 0 #df821f;
  border: medium none;
  border-radius: 2px;
  color: #fff;
  margin: 0;
  padding: 8px 5px 7px;
}
.sub-actionbtn > input:disabled {
  opacity: 0.5;
}
.sub-actionbtn.deletebtn > input {
  background: none repeat scroll 0 0 red;
  margin-left: 20px;
}
.cancelbtn {
  background: none repeat scroll 0 0 #333 !important;
}
.blue_heading {
  background: #012041 url(/assets/images/heading_bg.gif) repeat-x 0 0;
  padding: 0 10px;
  margin: 0;
  line-height: 30px;
  font-size: 0.85em;
  font-weight: bold;
  color: #fff;
  text-align: center;
}
.blue_heading1 {
  background: #012041 url(/assets/images/heading_bg.gif) repeat-x 0 0;
  padding: 0 10px;
  margin: 0;
  line-height: 33px;
  font-size: 1em;
  font-weight: bold;
  color: #fff;
}
span.green_text {
  color: #01c803;
}
.w300 {
  width: 300px;
}
.orange-gradient {
  background: #f69936;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #f69936),
    color-stop(100%, #dc6128)
  );
  background: linear-gradient(to bottom, #f69936 0, #dc6128 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f69936', endColorstr='#dc6128',GradientType=0 );
  border-color: #ce7400 !important;
}
.green {
  font-weight: bold;
  color: green;
}
.green-gradient {
  background: rgb(41, 154, 11);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(41, 154, 11, 1)),
    color-stop(100%, rgba(39, 135, 10, 1))
  );
  background: linear-gradient(to bottom, rgba(41, 154, 11, 1) 0, rgba(39, 135, 10, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#299a0b', endColorstr='#27870a',GradientType=0 );
  border-color: #006d00 !important;
}
.red {
  font-weight: bold;
  color: red;
}
.edit_row td {
  margin: 0 !important;
  padding: 0 4px !important;
  vertical-align: top;
  height: 30px;
}
.edit_row a {
  font-size: 10px;
}
#edit_ad_wrapper table {
  margin-top: 15px;
}
/********************* classes for differentiation of padding, border & color ********************/
.fl {
  float: left;
}
.fr {
  float: right;
}
.clear {
  clear: both;
}
.center {
  text-align: center;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.justify {
  text-align: justify;
}
.line_height {
  line-height: 2em;
}
.bold {
  font-weight: bold;
}
.pad {
  padding: 5px;
}
.pad1 {
  padding: 10px;
}
.pad15 {
  padding: 15px;
}
.pt {
  padding-top: 5px;
}
.pt1 {
  padding-top: 10px;
}
.pb {
  padding-bottom: 5px;
}
.pb1 {
  padding-bottom: 10px;
}
.pl {
  padding-left: 5px;
}
.pl1 {
  padding-left: 10px;
}
.pr {
  padding-right: 5px;
}
.pr1 {
  padding-right: 10px;
}
.mrg {
  margin: 5px;
}
.mt {
  margin-top: 5px;
}
.mt1 {
  margin-top: 10px;
}
.mt2 {
  margin-top: 20px;
}
.mb {
  margin-bottom: 5px;
}
.mb1 {
  margin-bottom: 10px;
}
.ml {
  margin-left: 5px;
}
.ml1 {
  margin-left: 10px;
}
.mr {
  margin-right: 5px;
}
.mr1 {
  margin-right: 10px;
}
.white_text {
  color: #ffffff;
}
.small_text {
  font-size: 0.85em;
}
.mid_align {
  vertical-align: middle;
}
.width_full {
  width: 100%;
}
.iframe,
.iframe body {
  background: #fff none repeat scroll 0 0 !important;
  padding: 5px;
}
.failure {
  color: Red;
}
#member_login table {
  width: 100%;
}
.gridHeader {
  background-color: #f5f5f5;
  border: 1px solid #999999;
  color: #000099;
  font-size: 16px;
  font-weight: bold;
  padding: 2px 3px;
  text-align: left;
}
.gridRow {
  padding: 2px 5px;
  border-bottom: solid 1px #323366;
}
.gridRow img {
  width: 100% !important;
}
.topBar {
  border: 1px solid #bebebe;
  border-radius: 5px 5px 5px 5px;
  color: #000000;
  margin-bottom: 10px;
  padding: 6px 14px;
}
.helpButton {
  float: right;
  margin-top: -1px;
  margin-right: 8px;
  margin-left: 12px;
}
#innerContent {
  clear: both;
  margin-top: 60px;
  position: relative;
}
.gridRow td {
  padding: 0.75rem;
}
.gridRow th {
  padding: 0.75rem;
  font-weight: bold;
}
.quartzeditbtn {
  padding: 0 0 6px !important;
}
.gridRow td {
  border: 1px solid #a0a0a0;
  max-width: 688px;
  overflow: hidden;
}
.pagecaption {
  color: #3b3e6b;
  float: left;
  font-family: alfa;
  font-size: 33px;
  line-height: 40px;
  margin: 0 0 25px;
}
.pagecaption-alt {
  color: #999;
  font-family: alfa;
  font-size: 20px;
  margin: 10px 0;
}
.lightTable {
  border: solid 1px #a0a0a0;
  border-collapse: collapse;
}
.lightTable td {
  border: solid 1px #a0a0a0;
}
.statusBar {
  background-color: #000000;
  color: #ffffff;
  font-size: 11px;
  font-weight: bold;
  height: 20px;
}
.statusBar a {
  color: #ffffff;
}
/* leader board */
.player_tables td,
.player_tables th {
  padding: 2px 5px;
}
.lbHeader table {
  border-style: solid;
}
.lbHeader th {
  white-space: nowrap;
  padding: 2px 3px 2px 3px;
}
.qualification_limit td {
  border-top-width: 3px;
  border-top-color: red;
}
.sysAdminHeader {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #990000;
  background-color: #e0ded5;
  padding: 2px 3px 2px 3px;
  border: solid 1px #990000;
}
.signup_prompt {
  color: #000;
  font-size: 14px;
  font-weight: bold;
}
.player_signup {
  float: left;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
}
.signin_form {
  text-align: left;
}
.signin_form table {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
}
.signin_form table tr {
  height: 37px;
}
.login_label {
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}
.login_value {
  text-align: left;
  font-size: 14px;
  padding-left: 6px;
}
.signup_instructions {
  font-weight: bold;
  margin-top: 55px;
  font-family: verdana;
  font-size: 14px;
  float: left;
  border-left: 1px solid #999999;
  padding-top: 5px;
  padding-bottom: 90px;
  padding-left: 41px;
}
.signup_instructions ul {
  padding: 0;
}
.signup_instructions li {
  font-style: italic;
  font-weight: normal;
  height: 32px;
  list-style: none;
  display: block;
  line-height: 32px;
  padding-left: 5px;
}
.signup_instructions .list_number {
  font-size: 18px;
  font-style: normal;
}
.player_signup a,
.signup_instructions a {
  text-decoration: underline;
  font-size: 10px;
}
#color_pickers {
  float: left;
  margin-top: 20px;
}
#color_pickers input {
  vertical-align: middle;
}
.player_header {
  color: #000099;
  font-weight: bold;
  font-size: 16px;
}

.tournamenttable table {
  width: 100%;
}
.lboardjumpmenulogic {
  border-radius: 5px 5px 5px 5px;
  height: 220px;
  list-style: none outside none;
  margin-bottom: 10px;
  overflow: hidden;
  width: 1000px;
}
.jumpmenutitle {
  color: #ffffff;
  float: left;
  font-family: alfa;
  font-size: 31px;
  font-weight: normal;
  margin-bottom: 5px;
  margin-top: 20px;
  padding: 10px;
  text-shadow: 1px 1px 2px #000000;
  width: 900px;
}
.jumpmenuleftheader {
  background: url('/gfx/leaderboards/jumpmenu.png') repeat scroll 0 0 #585584;
  float: left;
  height: 225px;
  margin-right: 15px;
  width: 40px;
}
.jumpmenucolumn {
  color: #ffffff;
  float: left;
  line-height: 30px;
  margin: 0 25px;
}
.jumpmenucolumn a {
  color: #ffffff;
  font-size: 15px;
  text-shadow: 1px 1px 2px #000000;
}
.jumpmenucolumn > li {
  list-style: disc outside none;
}
.hostalt {
  background: none repeat scroll 0 0 #585584;
  font-weight: bold;
  list-style: none outside none !important;
  margin-top: 6px;
  padding: 1px 43px 5px 18px;
  position: absolute;
  right: 0;
}
.lboardlogic {
  background: #ffffff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #ffffff),
    color-stop(57%, #bfbfbf),
    color-stop(100%, #ffffff)
  );
  background: linear-gradient(to bottom, #ffffff 0, #bfbfbf 57%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 );
  display: table;
  margin-bottom: 25px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;
}
.lboardthumb {
  background: none repeat scroll 0 0 gray;
  border: 1px solid #ffffff;
  -webkit-box-shadow: 1px 1px 2px;
  box-shadow: 1px 1px 2px;
  float: left;
  height: 200px;
  margin-bottom: 0;
  margin-right: 30px;
  position: relative;
  width: 200px;
  z-index: 100;
}
.lboardinfo {
  float: left;
  margin-top: 10px;
  width: 705px;
}
.lboardtitle {
  color: #2e3163;
  float: left;
  font-family: alfa;
  font-size: 30px;
  font-weight: normal;
  margin-top: 10px;
}
.titleaccent {
  color: #2e3163;
  float: left;
  font-size: 14px;
  font-weight: bold;
  margin-left: 7px;
  margin-top: 5px;
  padding-top: 0;
}
.lboarddescription {
  margin-top: 10px;
  float: left;
  width: 680px;
}
.lboardiconlogic {
  display: none;
  float: left;
  margin-top: 20px;
}
.lboardicon {
  background: none repeat scroll 0 0 yellow;
  float: left;
  margin-right: 10px;
  padding-left: 20px;
}
.lboardthlogic {
  float: left;
  margin-bottom: 60px;
  width: 100%;
}
.lboardthlogic {
  float: left;
  width: 100%;
}
.lboardbreak {
  background: none repeat scroll 0 0 #b3b3b3;
  height: 1px;
  margin: 30px 0;
}
.lboardviewlogic {
  display: table;
  margin: auto;
}
.lboardviewpic {
  height: 600px;
  margin: auto;
  width: 615px;
}
.lboardcopylogic {
  background: #f5effc none repeat scroll 0 0;
  border: 1px solid #ffffff;
  -webkit-box-shadow: 0 0 4px #8c8c8c;
  box-shadow: 0 0 4px #8c8c8c;
  padding: 20px 20px 40px;
  position: relative;
  width: 660px;
}
.lboarddescriptiontext {
  font-size: 13px;
  height: 128px;
}
.copybtn {
  background: none repeat scroll 0 0 darkblue;
  border-radius: 3px 0 0 0;
  bottom: 0;
  color: #ffffff;
  display: none;
  font-weight: bold;
  padding: 5px 29px;
  position: absolute;
  right: 0;
}
.lboardthtitle {
  margin: auto;
  text-align: center;
  width: 400px;
}
.lboardlpanel {
  float: left;
  height: 418px;
  width: 465px;
}
.lboardrpanel {
  width: 465px;
  float: right;
  height: 390px;
}
.lboardpanelcopylogic {
  background: #f5effc none repeat scroll 0 0;
  border: 1px solid #ffffff;
  -webkit-box-shadow: 0 0 4px #8c8c8c;
  box-shadow: 0 0 4px #8c8c8c;
  padding: 20px 20px 40px;
  position: relative;
  width: 423px;
}
.lboardpanelviewpic {
  background: none repeat scroll 0 0 yellow;
  -webkit-box-shadow: 0 0 3px #000000;
  box-shadow: 0 0 3px #000000;
  height: 258px;
  margin: auto;
  width: 380px;
}
.lboardtoggle {
  background: #202c59;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #202c59),
    color-stop(48%, #3c3870),
    color-stop(100%, #202c59)
  );
  background: linear-gradient(to right, #202c59 0, #3c3870 48%, #202c59 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#202c59', endColorstr='#202c59',GradientType=1 );
  border-radius: 0 0 5px 5px;
  bottom: 0;
  height: 60px;
  left: 0;
  position: absolute;
  width: 100%;
}
.togglebtn {
  background: none repeat scroll 0 0 #2b2951;
  border: 1px solid #7f7898;
  border-radius: 2px 2px 2px 2px;
  color: #ffffff;
  display: table;
  float: right;
  margin: 9px 10px 0 0;
  padding: 11px 43px;
}
.accordionButton {
  background: #202c59;
  background: url('/assets/images/leaderboards/toggle.png') no-repeat scroll right 2px;
  background: url('/assets/images/leaderboards/toggle.png') no-repeat scroll right 2px,
    -webkit-gradient(linear, left top, right top, color-stop(0, #202c59), color-stop(48%, #3c3870), color-stop(100%, #202c59));
  background: url('/assets/images/leaderboards/toggle.png') no-repeat scroll right 2px,
    linear-gradient(to right, #202c59 0, #3c3870 48%, #202c59 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#202c59', endColorstr='#202c59',GradientType=1 );
  border-bottom: 1px solid #ffffff;
  border-radius: 0 0 5px 5px;
  bottom: 0;
  cursor: pointer;
  float: left;
  height: 60px;
  left: 0;
  position: absolute;
  width: 1000px;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.accordionButton {
  _float: none; /* Float works in all browsers but IE6 */
}
.accordionContent {
  width: 938px;
  float: left;
  _float: none; /* Float works in all browsers but IE6 */
}
a.top {
  position: relative;
  top: -150px;
  display: block;
  height: 0;
  width: 0;
}
.systemadminbtn {
  float: right;
}
.systemadminbtn > a {
  background: none repeat scroll 0 0 #282f5f;
  border-radius: 2px;
  color: #ffffff;
  padding: 4px 5px;
  text-decoration: none;
}
.systemadminbtn > a:hover {
  background: none repeat scroll 0 0 #363d6d;
}
.adminsectionheader {
  display: table;
  font-family: alfa;
  font-size: 30px;
  line-height: 30px;
  margin-top: 30px;
  padding: 10px 0;
}
#allplayeractivitylogic {
  display: table;
  margin: auto;
}
#allplayeractivitylogic table {
  width: 1000px;
}
#allplayeractivitylogic th {
  background: none repeat scroll 0 0 #323366;
  font-family: arvoregular;
  font-size: 15px;
  font-weight: normal;
  line-height: 30px;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
#allplayeractivitylogic th:hover {
  background: none repeat scroll 0 0 #141548;
}
#allplayeractivitylogic th a {
  color: #ffffff !important;
  text-decoration: none;
}
.gridview-select-logic {
  display: table;
  padding: 0;
  width: 100%;
}
.quartzgrid {
  width: 100%;
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-collapse: collapse;
}
#quartzgridlogic {
  display: table;
  margin: auto;
}
.quartzgrid th {
  background: none repeat scroll 0 0 #323366;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  padding: 2px 15px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-collapse: collapse;
}
.quartzgrid th:hover {
  background: none repeat scroll 0 0 #141548;
}
.quartzgrid th a {
  color: #ffffff;
  text-decoration: none;
}
.quartzgrid th:nth-child(2) {
  padding-left: 5px !important;
  text-align: left;
}
.quartzgrid td {
  border-width: 1px !important;
  border-style: solid !important;
  border-collapse: collapse;
}
.quartzeditbtn > a {
  border: medium none;
  border-radius: 2px;
  color: #2f3264;
  font-size: 20px;
  padding: 0;
  text-decoration: none;
}
.deletebtn i {
  color: red;
  font-size: 22px;
}
.quartzpaging {
  border: medium none !important;
  clear: left;
  display: table;
  float: left;
  margin: 15px 6px;
  position: absolute;
  width: 100%;
  font-family: arial !important;
  font-weight: bold !important;
}
.quartzpaging a {
  background-color: #67689b;
  border: 1px solid #000000;
  border-radius: 3px;
  color: #ffffff !important;
  display: inline !important;
  font-size: 12px;
  padding: 7px;
  text-decoration: none;
  font-family: arial !important;
  font-weight: bold !important;
}
.quartzpaging a:hover {
  background-color: #323366;
  color: #00c157;
  border: 1px solid #000000;
}
.quartzpaging span {
  background-color: #3e3f72;
  border: 1px solid #000000;
  border-radius: 3px;
  color: #fff;
  padding: 7px;
}
tr.quartzpaging {
  background: none !important;
}
tr.quartzpaging tr {
  background: none !important;
}
tr.quartzpaging td {
  border: medium none;
  float: left;
  margin-left: 3px;
}
.quartzhorizontalmenu {
  margin: -13px 4px 5px 0;
}
.quartzmenuitem a {
  background: none repeat scroll 0 0 #282f5f;
  border-radius: 2px;
  color: #ffffff;
  display: table;
  font-weight: normal;
  margin: 0;
  padding: 5px;
  text-decoration: none;
}
.quartzmenuitem a:hover {
  background: none repeat scroll 0 0 #363d6d;
}
.quartzmenuitem .quartzmenuselect {
  background: none repeat scroll 0 0 #000434;
}
.quartzmenuitem .ls-current {
  background: none repeat scroll 0 0 #000434;
}
.quartzmenureportitem a {
  background: none repeat scroll 0 0 #282f5f;
  border-radius: 2px;
  color: #ffffff;
  font-weight: normal;
  line-height: 26px;
  margin: 0;
  padding: 8px 5px 7px !important;
  text-decoration: none;
}
.quartzmenureportitem a:hover {
  background: none repeat scroll 0 0 #363d6d;
}
.quartzmenureportitem .quartzmenuselect {
  background: none repeat scroll 0 0 #000434;
}
.quartzmenureportitem .ls-current {
  background: none repeat scroll 0 0 #000434;
}
.payrollmain {
  background: none repeat scroll 0 0 #ff0000;
  float: left;
  padding: 20px;
}
.staffmanagerbreakdown {
  background: none repeat scroll 0 0 #ffff00;
  float: left;
  padding: 30px 65px;
}
.staffpayrollbreakdown {
  clear: left;
  float: left;
  padding: 30px 0;
  width: 1000px;
}
.staffpayrollbreakdown table {
  float: left;
  height: 200px;
  margin: 0 75px 10px 0;
  width: 25%;
}
/***********************************************************************************************************************
 EXTRA STYLES ADDED FOR MOUSEOVER / ACTIVE EVENTS
************************************************************************************************************************/
.on {
  background: #202c59;
  background: url('/assets/images/leaderboards/toggle.png') no-repeat scroll 900px 2px,
    -webkit-gradient(linear, left top, right top, color-stop(0, #202c59), color-stop(48%, #3c3870), color-stop(100%, #202c59));
  background: url('/assets/images/leaderboards/toggle.png') no-repeat scroll 900px 2px,
    linear-gradient(to right, #202c59 0, #3c3870 48%, #202c59 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#202c59', endColorstr='#202c59',GradientType=1 );
  -webkit-transition: all 0.9s ease;
  transition: all 0.9s ease;
}
.horizon-swiper {
  background: #d1d4ef none repeat scroll 0 0;
  margin-bottom: 20px;
}
.horizon-item {
  font-size: 20px;
  margin-right: 10px;
  padding: 10px;
}
/***********************************************************************************************************************
zfilter Switches
************************************************************************************************************************/
.switch input {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.switch {
  display: table;
  float: left;
  height: 45px;
  margin: 10px 0 5px 8px;
  position: relative;
  z-index: 1;
}
.center-switch {
  display: table;
  float: none;
  margin: 10px auto;
}
.switch label {
  background: #a5a39d none repeat scroll 0 0;
  border-radius: 4px;
  display: block;
  height: 40px;
  line-height: 1.5 !important;
  position: relative;
  width: 130px !important;
}
.switch label:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
  border-radius: 3px;
  border: 1px solid #aeaeae;
  background: #ababab;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#f2f2f2), to(#ababab));
  background: linear-gradient(#f2f2f2, #ababab);
}
.switch label i {
  display: block;
  height: 100%;
  width: 60%;
  border-radius: inherit;
  background: silver;
  position: absolute;
  z-index: 2;
  right: 40%;
  top: 0;
  background: #b2ac9e;
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#f7f2f6), to(#b2ac9e));
  background: linear-gradient(#f7f2f6, #b2ac9e);
  -webkit-box-shadow: inset 0 1px 0 white, 0 0 8px rgba(0, 0, 0, 0.3), 0 5px 5px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 0 white, 0 0 8px rgba(0, 0, 0, 0.3), 0 5px 5px rgba(0, 0, 0, 0.2);
}
.switch label i::after {
  border-radius: inherit;
  content: 'Tap';
  font-size: 17px;
  height: 50%;
  left: 15%;
  position: absolute;
  text-align: center;
  top: 18%;
  width: 70%;
}
.switch label i::before {
  color: rgba(0, 0, 0, 0.4);
  content: 'no';
  font-family: Helvetica, Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  margin-top: -11px;
  position: absolute;
  right: -50%;
  text-transform: uppercase;
  top: 50%;
}
.switch input:checked ~ label {
  background: #5cb85c;
}
.switch input:checked ~ label i {
  right: 0;
}
.switch input:checked ~ label i::before {
  content: 'yes';
  right: 113%;
}
/***********************************************************************************************************************
zfilter Bootstrap Cards
************************************************************************************************************************/
.filter-card .switch {
  margin-bottom: 20px;
}
.ico-rotate {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.ico-rotate.down {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ico-rotate.up {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.quartz-main-content .card-header {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
}
.card-label-type-one {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}
.card {
  border-left: none !important;
  border-right: none !important;
  border-top: none !important;
  border-bottom: 1px solid #ffffff !important;
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0 25px -10px #3b3e6b;
  background: #f7f7f7;
  background: -moz-radial-gradient(center, ellipse cover, #f7f7f7 0%, #ffffff 100%);
  background: -webkit-radial-gradient(center, ellipse cover, #f7f7f7 0%, #ffffff 100%);
  background: radial-gradient(ellipse at center, #f7f7f7 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#ffffff',GradientType=1 );
}
#quartz-main-left .card {
  overflow: visible;
}
.card-block {
  display: table;
  width: 100%;
}
.card-interior {
  background: #eaeaea none repeat scroll 0 0;
}
.card-title {
  color: #37474f;
  display: block;
  font-size: 18px;
  padding: 20px 0;
}
.btn-primary {
  border-color: #0275d8 !important;
}
.plblogin .card-header {
  color: #76838f;
  text-align: left;
}
.card-header {
  border-bottom: 1px solid #e5e5e5;
  color: #3b3e6b;
  display: block;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
  overflow: hidden;
  padding: 17px 15px 14px !important;
}
.card-header i {
  width: 23px;
  text-align: center;
}
.card-header .mat-icon {
  float: left;
  margin-right: 4px;
  height: 21px;
  line-height: 20px;
}
.card-header .icon-dealer {
  font-size: 21px;
  line-height: 0;
  position: relative;
  top: 2px;
}
.card-footer {
  padding: 13px;
  margin-top: 20px;
}
.card-header-right {
  float: right;
  font-size: 1.4rem;
  margin-top: 2px;
}
.tournament-count-logic {
  background: #3b3e6b none repeat scroll 0 0;
  border-radius: 5px;
  clear: left;
  color: #fff;
  float: left;
  font-size: 4rem;
  margin: 5px 0 15px;
  text-align: center;
  width: 100%;
}
.card,
.card-title {
  margin-bottom: 2rem;
}
.filter-card {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  margin-bottom: 20px;
}
.dashboard-leage-details-input-name > input {
  border: 1px solid #3b3e6b;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  padding: 1rem;
  width: 100%;
}
.mat-form-field-prefix {
  position: relative;
  top: -4px;
}
.editor-mat-field {
  width: 100%;
}
.editor-mat-field input {
  border-radius: 0 !important;
  border: none !important;
  padding: 0 !important;
}
.editor-mat-field textarea {
  border-radius: 0 !important;
  border: none !important;
  padding: 0 !important;
}
.editor-mat-field .mat-select-value-text {
  font-size: 18px;
}
.date-selector .mat-input-element {
  margin-top: -5px;
}
.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.mat-input-element {
  caret-color: inherit !important;
  color: inherit !important;
}
.leaderboard-card-selection-logic .mat-form-field-label {
  color: inherit;
}
.leaderboard-card-selection-logic .mat-focused .mat-form-field-label {
  color: inherit !important;
}
.mat-calendar-body-selected {
  background-color: #3b3e6b !important;
  color: white;
}
.card input {
  border-radius: 3px;
  padding: 3px 5px;
  font-size: 18px;
  border: 1px solid #3b3e6b;
  width: 100%;
}
.card textarea {
  border: 1px solid #3b3e6b;
  border-radius: 3px 3px 3px 3px;
  font-size: 18px;
  padding: 1rem;
  width: 100% !important;
}
.leaderboard-card textarea {
  border-radius: 0 3px 3px 3px !important;
}
.card select {
  border: 1px solid #3b3e6b;
  border-radius: 0.25rem;
  font-size: 18px;
  padding: 5px 3px;
  width: 100%;
}
.leaderboard-mat-panel textarea {
  border: 1px solid #3b3e6b;
  border-radius: 0 3px 3px 3px;
  font-size: 18px;
  padding: 1rem;
  width: 100% !important;
}
.tournament-players-tab select {
  max-width: 300px;
}
.quartz-input {
  border: 1px solid #3b3e6b;
  border-radius: 0.25rem;
  font-size: 18px;
  padding: 1rem;
  width: 100% !important;
}
.card .gridview-logic {
  border: medium none !important;
  margin: -24px -17px -10px;
}
.card input::placeholder {
  font-size: 18px;
}
.card select::placeholder {
  font-size: 18px;
}
.card textarea::placeholder {
  font-size: 18px;
}
.pagination {
  margin-left: 6px;
  margin-bottom: 1px;
}
.gridview-logic .table-bordered {
  border: none;
}
.gridview-logic a {
  cursor: pointer;
}
.gridview-padding {
  padding: 8px;
}
.gridview-logic td {
  font-size: 17px;
}
.gridview-logic td:first-child {
  padding-left: 14px;
}
.card-admin {
  padding-bottom: 0;
  padding-top: 0;
}
.inputheader {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
}
.toggle-label {
  font-size: 16px;
}
.panelrow {
  margin-bottom: 15px;
  width: 100%;
}
.card-block h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px !important;
  margin-top: 5px;
}
.filter-row > input {
  border: 1px solid #3b3e6b;
  font-size: 16px;
  width: 100%;
}
.filter-card .btn {
  max-width: 300px;
}
.panelrow-combine {
  background: none repeat scroll 0 0 #8e91be;
  display: table;
  margin: 20px 0;
  padding: 10px 10% 10px 15px;
  width: 100%;
}
.panelrow.row-eventinvite {
  float: right;
  text-align: center;
}
.panelrow.row-membership {
  float: left;
  text-align: left;
  width: 40%;
}
.editpanel-deletesub {
  background: #ff8484 none repeat scroll 0 0;
  float: right;
  padding: 30px 10px;
  text-align: center;
}
.editpanel-deletesub > input {
  background: none repeat scroll 0 0 red;
  border: medium none;
  border-radius: 2px;
  color: #fff;
  padding: 10px;
}
.editpanel.inputlogic {
  background: rgb(235, 235, 235);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, rgba(235, 235, 235, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: linear-gradient(to bottom, rgba(235, 235, 235, 1) 0, rgba(255, 255, 255, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#ffffff',GradientType=0 );
  display: table;
  padding: 17px 0;
  width: 100%;
}
.filter-btn {
  background: #323366 none repeat scroll 0 0 !important;
  border-color: #141548 !important;
  float: right;
  margin: -5px -3px -4px;
}
.filter-btn:hover {
  background: #1a1b4e none repeat scroll 0 0 !important;
  border-color: #141548;
}
.mfilter-btn {
  background: #323366 none repeat scroll 0 0 !important;
  border-color: #141548;
  margin: 10px 0 20px;
}
.filter-btn:hover {
  background: #141548 none repeat scroll 0 0;
}
.footer-pagination {
  min-height: 54px;
}
.filter-row {
  clear: left;
  margin-bottom: 15px;
}
.card-footer a {
  position: relative;
  z-index: 100;
}
.card-footer.text-right input {
  border-radius: 4px;
  font-size: 1.5rem;
  padding: 4px 10px;
  position: relative;
  z-index: 1;
}
.panel-delete-btn {
  height: 30px;
  margin: 10px 0 0;
  padding: 0 !important;
  width: 30px !important;
}
.panel-delete-btn.delete-alt {
  margin-top: 40px;
}
.card input[type='text']:focus {
  background: #f5effc none repeat scroll 0 0;
  color: #000;
}
.card textarea:focus {
  background: #f5effc none repeat scroll 0 0;
}
.card-header .btn-primary {
  background: #3b3e6b !important;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(30%, #3b3e6b),
    to(#323366)
  ) !important;
  background: linear-gradient(to bottom, #3b3e6b 30%, #323366 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3e6b', endColorstr='#323366',GradientType=0 );
  border: none;
  transition: unset;
  outline: none !important;
  box-shadow: none !important;
}
.card-header .btn-primary:hover {
  background: #323366 none repeat scroll 0 0 !important;
}
.list-group-item .btn-primary {
  background: #3b3e6b;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(30%, #3b3e6b),
    to(#323366)
  );
  background: linear-gradient(to bottom, #3b3e6b 30%, #323366 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3b3e6b', endColorstr='#323366',GradientType=0 );
  border: none;
}
.list-group-item .btn-primary:hover {
  background: #323366 none repeat scroll 0 0;
}
.list-group-item {
  border: none;
  background: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#eeeeee));
  background: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eeeeee',GradientType=0 );
}
.collapse .list-group {
  border-top: 1px solid #e5e5e5;
  margin-bottom: 0;
  padding-left: 0;
}
.card-header-edit-label {
  background: #3b3e6b;
  color: #fff;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
  position: relative;
  top: -3px;
  margin-right: 4px;
}
.show-overflow {
  overflow: visible;
}
.page-footer {
  padding: 0;
  margin-top: -13px;
  border: none;
}
.mat-paginator-icon {
  width: 34px !important;
}
.page-footer .mat-paginator {
  color: #3b3e6b;
  font-weight: bold;
  font-size: 13px !important;
}
.card-footer {
  margin-top: 7px !important;
}
.my-drop-zone {
  border: dotted 3px lightgray !important;
  min-height: 20px;
  padding: 2px 10px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}
/* Default class applied to drop zones on over */
.my-drop-zone.over {
  border: dotted 3px #4caf50 !important;
  background-color: #ffffe7 !important;
}
.drop-zone-content {
  text-align: center;
  color: #9e9e9e;
  width: 100%;
}
.drop-zone-content .mat-icon {
  width: 100%;
  text-align: center;
  font-size: 35px;
}
.file-drop-logic {
  clear: left;
}
.file-drop-or {
  text-align: left !important;
  float: left;
}
.drop-zone-info {
  cursor: move;
}
.file-input-btn {
  background: #9e9e9e;
  padding: 20px 0;
  font-size: 16px;
  border-radius: 3px;
  color: #fff;
  width: 100%;
  cursor: pointer;
}
.file-input-btn:hover {
  background: #8a8a8a;
}
.pending-file-logic {
  background: #4caf50;
  color: #fff;
  padding: 10px 50px 10px 10px;
  border-radius: 3px;
  display: table;
}
.pending-file-icon {
  float: left;
  height: 45px;
  font-size: 40px;
  margin-right: 14px;
  line-height: 48px;
}
.pending-file-content {
  float: left;
  display: table;
}
.profile-colors .color-picker {
  left: 10% !important;
  top: 34px !important;
}
.ad-card {
  display: block;
  margin: auto;
}
.apc-profile-links .ad-card {
  float: left;
}
.playerprofile-footer .ad-card {
  margin-top: 15px;
}
.card-info-guide-btn {
  float: left;
  margin-right: 10px;
  background: #3b3e6b;
  color: #fff;
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 3px;
  cursor: pointer;
}
@media only screen and (min-width: 768px) {
  .card textarea {
    width: auto;
  }
}
/***********************************************************************************************************************
zfilter ADMIN Dashboard
************************************************************************************************************************/
.dashboard-leage-details-input-logo {
  margin: 5px 0 10px;
  display: table;
}
.league-logo-preview {
  margin: 10px auto 0;
  display: table;
}
.inputnotice {
  color: #3b3e6b;
  font-size: 13px;
}
.dashboard-leage-details-input-logo > img {
  max-width: 350px;
  width: 100%;
  margin-bottom: 20px;
}
.recent-activity table {
  border: medium none !important;
  width: 100% !important;
}
.recent-activity table tr {
  border: medium none !important;
}
.recent-activity th {
  text-align: center !important;
}
.recent-activity-grid {
  margin: 0 -13px;
}
.recent-activity {
  padding: 0;
}
.recent-activity table.gridRow {
  border: medium none !important;
}
#ctl00_ContentPlaceHolder1_lblAcctCurrent.paragraph {
  background: #5cb85c none repeat scroll 0 0;
  border-radius: 4px;
  margin-top: 0;
  padding: 4px 12px;
  text-align: center;
  color: #fff;
}
#ctl00_ContentPlaceHolder1_lblAcctCurrent.error {
  background: #f2dede none repeat scroll 0 0;
  border-radius: 4px;
  margin-top: 0;
  padding: 4px 12px;
  text-align: center;
}
.card-membership-iscurrent-content {
  background: #5cb85c none repeat scroll 0 0;
  border-radius: 4px;
  color: #fff;
  display: none;
  margin-top: 0;
  padding: 4px 12px;
  text-align: center;
}
.Dashboard #innerContent {
  margin-top: 20px;
}
.dashboard-help-block .paragraph {
  font-size: 13px;
}
.dashboard-leaguename input {
  float: left;
  width: 60%;
  margin-right: 10px;
}
.leaguename-save {
  border: medium none !important;
  padding: 0px !important;
  width: 19% !important;
  position: relative;
  top: 12px;
  max-width: 75px;
  color: #fff !important;
  background: #4caf50 !important;
  line-height: 30px !important;
}
.leaguename-text {
  float: left;
  margin-right: 1%;
  width: 80% !important;
}
.confirm-btn {
  margin-bottom: 10px;
  width: 100%;
}
.confirm-amount {
  border: 1px solid #5cb85c;
  border-radius: 4px;
  color: #5cb85c;
  display: table;
  font-size: 30px;
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 98%;
}

.dashboard-leage-details-input-logo input {
  border: none;
  padding: 0;
}

.billing-panel-section {
  background: #a4d5a6;
  margin: 8px -10px -13px;
  clear: left;
  padding: 15px 10px;
  color: #2e6330;
  border-top: 10px solid #4caf50;
}
.dashboard-billing-sep {
  float: left;
  margin: 3px;
}
.dashboard-panel-heading-sub {
  font-weight: 700;
  margin-bottom: 2px;
}
.dashboard-panel-heading {
  font-size: 30px;
  line-height: 32px;
  font-weight: 300;
}
.dashboard-panel-heading.created {
  font-size: 23px;
  line-height: 25px;
}
.dashboard-inputnotice {
  color: #3b3e6b;
  font-size: 13px;
}
.renewal-notice {
  color: red;
  font-size: 18px;
  font-weight: 300;
}
.executiveterms-logic {
  margin: 15px 0 0;
  display: table;
  width: 85%;
}
.exeterms-link {
  cursor: pointer;
}
/***********************************************************************************************************************
zfilter ADMIN Players
************************************************************************************************************************/
.player-merge-btn {
  margin-top: 12px;
}
.player-search {
  margin: 0;
  padding: 0 0.7rem 1.3rem !important;
}
.switch-toggle {
  margin: 10px 0;
}
.gridview-logic.grid-playeractivity table tr:nth-child(1) {
  background: rgba(0, 0, 0, 0.05);
}
.gridview-logic.grid-playeractivity table tr:nth-child(1) td {
  padding: 0.75rem;
}
.gridview-logic.grid-playeractivity table tr:nth-child(1) td:nth-child(1) {
  padding: 0.75rem 0.75rem 0.75rem 14px;
}
/***********************************************************************************************************************
zfilter ADMIN Profile Page
************************************************************************************************************************/
.avatar-card-leagueseason {
  display: table;
  width: 100%;
  margin: 10px 0 5px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
  padding: 20px 0;
}
.profile-avatar-image {
  max-width: 100%;
  width: 100%;
}
.player-profile-main {
  margin-left: auto;
  margin-right: auto;
  max-width: 1040px;
  position: relative;
  width: 100%;
  z-index: 1;
}
.quickstat-label {
  clear: both;
  width: 100%;
  display: table;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 30px;
}
.quickstat {
  margin: 5px 0;
}
.quickstat-size-1 {
  font-size: 65px;
  font-weight: 300;
  line-height: 55px;
}
.quickstat-size-2 {
  font-size: 45px;
  font-weight: 300;
  line-height: 40px;
}
.quickstat-size-3 {
  font-size: 35px;
  font-weight: 300;
  line-height: 26px;
}
.quickstat-size-4 {
  font-size: 30px;
  font-weight: 300;
  line-height: 28px;
}
.profile_light_box {
  background-color: #f0f0f0;
  width: 350px;
  border: 1px solid #e0e0e0;
  padding: 9px;
  margin-bottom: 18px;
}
#profile_right {
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 6px;
}
#profile_sponsors_main {
  display: table;
  margin: auto;
  text-align: center;
}
.s_cont {
  text-align: center;
  margin-bottom: 8px;
}
#playerprofile_footerad {
  padding: 22px 36px;
  border-bottom: 1px solid #e5e5e5;
  text-align: center;
}
.playerprofile-ad {
  background: black none repeat scroll 0 0;
  padding: 23px 23px 25px 21px;
  text-align: center;
}
.playerprofile-footer {
  background: #ffffff none repeat scroll 0 0;
  padding: 1px 0 15px 0;
  text-align: center;
}
.player-profile-logic {
  background: #323366 none repeat scroll 0 0;
  display: table;
  margin: auto;
  padding: 20px 0;
  position: relative;
  width: 100%;
  min-height: 100vh;
}
.player-profile-logic .card {
  background: #ffffff;
}
.playerprofile_header > div {
  margin: auto;
  max-width: 1000px;
}
#main_profile_table {
  border: 1px solid #e5e5e5;
  border-collapse: collapse;
  width: 100%;
}
#main_profile_table > tr > td,
#main_profile_table > tbody > tr > td {
  border: 1px solid #e5e5e5;
  vertical-align: top;
}
#profile_left {
  width: 406px;
  padding-top: 8px;
  padding-left: 17px;
  padding-right: 12px;
}
#venue_stats .gridview-logic tbody tr th:nth-child(3) {
  padding: 2px 15px 2px 3px !important;
  text-align: left !important;
}
#venue_stats .gridRow td:nth-child(3) {
  display: table-cell;
  padding: 2px 15px 2px 3px !important;
  text-align: left !important;
}
#venue_stats .gridRow:nth-child(2n + 1) {
  background: #eee none repeat scroll 0 0;
}
.playerprofile-grid-venue {
  padding-bottom: 0;
}
.grid-profile-tournament {
  padding-bottom: 0;
  margin: -16px -8px 0px !important;
}
.grid-profile-tournament th a {
  color: #fff;
}
.grid-profile-tournament th:nth-child(1),
.grid-profile-tournament td:nth-child(1) {
  text-align: center;
}
.grid-profile-tournament th:nth-child(3),
.grid-profile-tournament td:nth-child(3) {
  text-align: center;
}
.playerprofile-card-block {
  padding-bottom: 0 !important;
}
.gridview-logic.grid-profile-tournament .gridRow:nth-child(2n + 1) {
  background: #eee none repeat scroll 0 0;
}
#tournament_stats .gridview-logic tbody tr th:nth-child(3) {
  display: table-cell;
}
#tournament_stats .gridRow td:nth-child(3) {
  display: table-cell;
  text-align: left !important;
}
#tournament_stats .gridRow td {
  padding: 8px 0 6px;
  font-weight: bold;
}
.avatar-card-leaguelogo img {
  border: medium none !important;
  max-width: 100%;
}
.customize-profile-link > a {
  border: 1px solid #212256;
  border-radius: 3px;
  color: #fff;
  font-family: gudea;
  font-size: 15px;
  margin: auto;
  padding: 10px 20px 8px;
  text-align: center;
}
.customize-profile-link > a:hover {
  background: #212256;
}
.customize-profile-link {
  margin: 0 0 20px;
  text-align: center;
}
.verify-profile-link > a {
  background: orange none repeat scroll 0 0;
  border: 1px solid #212256;
  border-radius: 3px;
  color: #000;
  font-family: gudea;
  font-size: 15px;
  margin: auto;
  padding: 10px 20px 8px;
  text-align: center;
}
.verify-profile-link > a:hover {
  background: #212256;
  color: #fff;
}
.verify-profile-link {
  margin: 10px 0;
  text-align: center;
}
.action-verify-button {
  background: #ff9800 !important;
  font-family: roboto !important;
  font-weight: 700 !important;
  color: #fff !important;
}
.verify-profile-footer {
  color: #7071ad;
  margin-bottom: 30px;
  margin-top: -3px;
  text-align: center;
}
.player-profile-areyou {
  color: #fff;
  font-size: 16px;
  margin-bottom: 15px;
  text-align: center;
}
.fb_iframe_widget span {
  width: 100% !important;
}
.profile-avatar-logic {
  border: 3px solid #fff;
  -webkit-box-shadow: 0 -7px 40px -10px #000;
  box-shadow: 0 -7px 40px -10px #000;
  display: block;
  margin: 25px auto 15px;
  position: relative;
  z-index: 1;
  display: table;
  height: 200px;
}
.profile-avatar {
  height: 200px;
  max-width: 350px;
  width: auto;
  display: table;
  position: relative;
}
.card-header.profile-avatar-card {
  border-bottom: none;
  height: 200px;
  margin-bottom: -185px;
  opacity: 0.8;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.card-header.profile-avatar-card span {
  background: rgba(0, 0, 0, 0) url(http://www.transparenttextures.com/patterns/diagonal-noise.png)
    repeat scroll 0 0;
  height: 100%;
  z-index: 1;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.avatar-card-name {
  font-family: alfa;
  font-size: 27px;
  margin: 5px 0;
  text-align: center;
  width: 100%;
  position: relative;
  z-index: 1;
}
.avatar-league-name {
  font-weight: 900;
  text-align: center;
  margin-top: -20px;
  z-index: 100;
  position: relative;
  font-size: 15px;
}
.profile-name-effect {
  font-family: alfa;
  font-size: 27px;
  margin: -14px 0 3px;
  text-align: center;
  -webkit-transform: rotateX(180deg) rotateY(180deg);
  transform: rotateX(180deg) rotateY(0);
  width: 100%;
  overflow: hidden;
  z-index: 0;
  line-height: 32px;
  color: #eaeaea;
}
.profile-name-effect span {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    color-stop(58%, rgba(255, 255, 255, 1)),
    to(rgba(255, 255, 255, 1))
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 58%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  width: 100%;
  position: absolute;
  height: 40px;
  z-index: 0;
}
.avatar-rank-title {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}
.avatar-rank-rank {
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
}
.avatar-card-leaguelogo {
  width: 66px;
  height: 66px;
  float: left;
  background-size: cover;
  background-position: center;
  border-radius: 2px;
}
.avatar-card-select {
  float: left;
  width: 71%;
  margin-left: 15px;
}
.avatar-card-quickstats {
  display: table;
  float: left;
  width: 100%;
}
#venue_stats {
  clear: both;
}
#venue_stats h4,
#tournament_stats h4 {
  padding-top: 23px;
}
.italic {
  font-style: italic;
}
.playerprofile-ad img {
  height: auto !important;
  width: auto !important;
}
#imgLeagueLogo {
  margin: 10px 0 20px;
  max-height: 200px;
  max-width: 350px;
  width: 100% !important;
}
.card.ad-card img:first-child {
  height: auto;
  max-width: 300px;
  width: 100%;
}
.uploadfield {
  float: left;
  margin-right: 1%;
  width: 58%;
}
#playerprofile_footer {
  text-align: center;
  padding: 3px 0;
  color: Black;
}
#playerprofile_footer a {
  text-decoration: underline;
  color: #000099;
}
.playerprofile-ad img:nth-child(2) {
  display: none;
}
#player_background_ad_left,
#player_background_ad_right {
  position: absolute;
  z-index: 0;
  margin-top: -20px;
}
#player_background_ad_right {
  right: 0;
}
.playerprofile_header img {
  width: 100% !important;
}
#playerprofile_footerad img {
  width: 100%;
}
#playerprofile_footerad a img {
  height: auto;
  width: 100%;
}
#player_profile {
  background: #323467;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1%, #323467),
    color-stop(43%, #333367),
    color-stop(100%, #022042)
  );
  background: linear-gradient(to bottom, #323467 1%, #333367 43%, #022042 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#323467', endColorstr='#022042',GradientType=0 );
}
.playerprofile_header a {
  color: #fff;
}
.adspot-logic {
  display: table;
  margin: 0 auto 23px;
}
.ad-card iframe {
  text-align: center;
  margin: auto !important;
  display: block;
  border-radius: 3px;
  overflow: hidden;
}
.ad-card img {
  text-align: center;
  margin: auto !important;
  display: block;
  border-radius: 3px;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}
.avatar-card-hide {
  display: none;
}
.profile-footer-link {
  display: table;
  margin: 10px auto -10px;
  text-align: center;
}
.profileun-footer-link {
  display: table;
  margin: 10px auto -10px;
  text-align: center;
}
.statpreviewlink {
  color: #7071ad;
  cursor: pointer;
  text-decoration: underline !important;
}
.card-playersignup .leaderboard-season:first-child {
  margin-top: -9px;
}
#collapseInstructions {
  max-width: 450px;
  width: 100%;
}
.playerprofile-ad .card.ad-card img {
  max-width: 750px;
  width: 100%;
}
.playerprofile-ad .card.ad-card {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
}
.grid-profile-tournament .gridRow {
  border: none;
  padding: 0;
}
.profile-footer {
  margin-top: 0px !important;
}
.grid-profile-tournament th {
  padding: 7px 10px !important;
  font-weight: bold;
}
.profilegrid-venuestats .profilegrid-column-number {
  width: 5%;
  text-align: center;
  padding: 7px 0;
  margin: 0;
}
.profilegrid-venuestats .profilegrid-column-venue {
  width: 70%;
  text-align: left;
  padding: 7px 10px;
  margin: 0;
}
.profilegrid-venuestats .profilegrid-column-points {
  width: 25%;
  text-align: center !important;
  padding: 7px 0;
  margin: 0;
}

.profilegrid-tourstats .profilegrid-column-number {
  width: 5%;
  text-align: center;
  padding: 7px 0;
  margin: 0;
}
.profilegrid-tourstats .profilegrid-column-venue {
  width: 57%;
  text-align: left;
  padding: 7px 10px;
  margin: 0;
}
.profilegrid-tourstats .profilegrid-column-points {
  width: 11%;
  padding: 7px 0;
  margin: 0;
}
.profilegrid-tourstats .profilegrid-column-date {
  width: 27%;
  text-align: center;
  padding: 7px 0;
  margin: 0;
}

.playerprofile-card-block .gridview-logic td:first-child {
  padding: 0;
}
.profile-sharing-block {
  float: right;
  margin-left: 7px;
}

.profile-sharing-logic {
  width: 100%;
  display: table;
}
.profile-sharing-icon {
  font-size: 40px;
}
.profile-sharing-icon a {
  color: #3b3e6b;
}
.profile-viewleaderboard {
  background: #d8d8d8;
  color: #3b3e6b;
  padding: 6px 12px;
  border-radius: 3px;
  display: table;
  float: right;
}
.profile-sharing-title {
  margin: 10px 0 0;
  font-weight: bold;
  color: #3b3e6b;
}
.example-profile-image {
  text-align: center;
  max-width: 400px;
  margin: auto;
  display: block;
}

.example-profile-image img {
  width: 100%;
}
#sample_profile {
  margin-top: 0;
}
#sample_profile > img {
  border: 1px solid #fff;
  -webkit-box-shadow: 0 0 10px -6px #000;
  box-shadow: 0 0 10px -6px #000;
  display: table;
  margin: 0 auto;
  width: 88%;
}
#sample_profile h4 {
  text-align: center;
}
.profile-panel-heading-sub {
  font-weight: 700;
  margin-bottom: 2px;
}
.profile-colorpicker-section {
  margin: 10px 0;
}

.profile-colorpicker-section .color_picker {
  width: 100% !important;
  min-height: 45px !important;
  cursor: pointer;
  box-shadow: 0 0 0px 2px rgba(0, 0, 0, 0.2);
}
.profile-sample-form-logic {
  margin: 0px 0 20px;
  padding: 20px;
}
.profile-instructions-button {
  background: #3b3e6b;
  font-size: 13px;
  color: #fff;
  padding: 8px;
  border-radius: 2px;
  position: absolute;
  right: 12px;
  top: 11px;
  cursor: pointer;
}
@media screen and (max-width: 500px) {
  span.quickstats_label {
    font-size: 14px;
  }
  .quickstats-stat {
    font-size: 20px;
    line-height: 20px;
  }
  .quickstats-stat.stat-rank {
    font-size: 55px;
    line-height: 55px;
  }
  .quickstats-stat.stat-wins span {
    font-size: 30px;
    line-height: 30px;
  }
}
/***********************************************************************************************************************
zfilter ADMIN Dealers
************************************************************************************************************************/
.dealer-time-input {
  border: 2px solid #3b3e6b;
  width: 65px;
}
.tournament-dealers-logic {
  padding: 12px 5px;
  display: table;
  width: 100%;
}
.merge-btn {
  max-width: 300px;
}
.gridview-logic.grid-dealeractivity table tr:nth-child(1) {
  background: rgba(0, 0, 0, 0.05);
}
.gridview-logic.grid-dealeractivity table tr:nth-child(1) td {
  padding: 0.75rem;
}
.gridview-logic.grid-dealeractivity table tr:nth-child(1) td:nth-child(1) {
  padding: 0.75rem 0.75rem 0.75rem 14px;
}
/***********************************************************************************************************************
zfilter ADMIN Tournaments
************************************************************************************************************************/
.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none !important;
}
.card-btn-check {
  clear: left;
  display: table;
  font-size: 17px;
  margin-bottom: 5px;
}
.card-btn-check > label {
  margin-left: 8px;
}
.tournaments-financials-addrow {
  clear: left;
  float: left;
}
.tournaments-financials-totals {
  clear: left;
}
.tournaments-financials-items input {
  width: 100%;
}
.tournaments-financials-items .panelrow {
  clear: left;
}
.itemrow input.delete-btn-image {
  width: 35px;
}

.itemrow .col-md-6 {
  position: inherit;
}
.itemsrow {
  margin: 0 -7px;
}
.itemrow {
  display: table;
  margin: 0;
  width: 100%;
  position: relative;
  padding: 16px 9px 0;
}
.itemrow:nth-child(2n) {
  background: rgba(0, 0, 0, 0.05) none repeat scroll 0 0;
}
.itemrow input {
  width: 100%;
}
.total-row-title {
  font-size: 16px;
  font-weight: bold;
  margin-top: 30px;
  text-align: center;
}
.panelrow.totalpercent::before {
  content: 'PERCENT';
  display: table;
  text-align: center;
  width: 100%;
}
.panelrow.totalamount::before {
  content: 'AMOUNT';
  display: table;
  text-align: center;
  width: 100%;
}
.tournamentedit-delete-btn > input {
  width: 31px !important;
}
.tournaments-financials-column-staff {
  float: left;
}
.tournaments-financials-column-staff input {
  border: 2px solid #3b3e6b;
  font-size: 15px;
  width: 100%;
}
.tournaments-financials-column-payout {
  width: 40%;
}
.tournaments-financials-column-delete {
  float: right;
  width: 10%;
}
.tournaments-financials-column-payout input {
  margin: 10px;
  padding: 1px !important;
  width: 100% !important;
}
.itemsrow input {
  border: 1px solid #3b3e6b;
  width: 100%;
}
.card-block.items-block {
  padding: 0;
}
.tournaments-financials-column-item > input {
  display: table;
  width: 90%;
}
.tournaments-financials-column-payoutpercent > input {
  display: table;
  margin: auto;
  width: 90%;
}
.tournaments-financials-column-amtowed > input {
  display: table;
  margin: auto;
  width: 90%;
}
.tournaments-financials-column-item {
  width: 50%;
}
.tournaments-financials-column-payoutpercent {
  width: 20%;
}
.tournaments-financials-column-amtowed {
  width: 20%;
}
.tournaments-financials-column-delete > input {
  float: right;
  margin-top: -13px;
  width: 44px;
}
.staffrow {
  clear: left;
  margin: 0 -13px;
}
.tournaments-financials-row table tbody .staffrow:nth-child(2n + 1) {
  background: #eee none repeat scroll 0 0;
}
.staffrow .tournaments-financials-column-staff {
  padding: 12px 15px 3px !important;
  width: 100%;
}
.tournaments-panel-btn {
  margin: 0 auto 13px;
  width: 100%;
}
.swiper-tournament.horizon-swiper {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  margin: 0;
  padding: 0;
  width: 100%;
}
.swiper-tournament.horizon-swiper .horizon-item {
  font-size: 2rem;
  padding: 0;
}
.swiper-tournament.horizon-swiper .horizon-outer {
  margin: 0;
  padding: 0;
}
.tournaments-rulelink-logic {
  margin-bottom: 25px;
}
.card-header.panel-static-header {
  font-weight: normal;
  padding: 13px 21px !important;
}
.horizon-item .btn.btn-primary {
  line-height: 1;
  padding: 2px 5px;
}
.inner-table-row {
  margin-bottom: 10px;
}
.inner-table-row .inputheader {
  clear: both;
  display: table;
}
.tournament-players-name-column {
}
.tournament-players-name {
  border: 2px solid #3b3e6b;
  margin-right: 15px;
}
.tournament-players-txtpoints {
  border: 2px solid #3b3e6b;
}
.tournament-players-row:nth-child(2n + 1) {
  background: rgba(0, 0, 0, 0.05) none repeat scroll 0 0;
}
.tournament-dealers-logic:nth-child(2n) {
  background: rgba(0, 0, 0, 0.05) none repeat scroll 0 0;
}
.tournament-players-place {
  color: #3b3e6b;
  font-size: 20px;
  text-align: center !important;
}
.tournament-players-table-logic {
  margin: 0px -9px -12px;
}
.tournament-details-panel {
  background: #eee none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  margin: -25px -9px 15px;
}
.tournament-details-panel .btn.text-right {
  border-radius: 0;
}
.tournament-tab-card {
  display: block;
  margin: -8px -13px !important;
  width: auto;
}
.tournament-players-row .inputheader {
  display: none;
}
.tournament-players-row:nth-child(2) .inputheader {
  display: block !important;
}
.update-dealers {
  max-width: 400px;
}
.panelrow.top-section {
  background: rgba(231, 231, 235, 0.85) none repeat scroll 0 0;
  margin: -1px -12px 29px;
  padding: 15px 12px 0;
  width: 105%;
  display: table;
}
.panelrow.top-section-dealers {
  background: rgba(231, 231, 235, 0.85) none repeat scroll 0 0;
  margin: -1px -12px 0;
  padding: 15px 12px 0;
  width: 105%;
  display: table;
}
.update-points {
  display: table;
  font-family: gudea;
  font-size: 12px !important;
  margin-left: 5px;
  max-width: 396px;
  padding: 8px 0 6px !important;
  width: 120px !important;
}
.tournament-dealers-block-logic {
  margin: 0 -4px 10px;
}
.totalrow input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 2px solid;
}
.totalrow {
  margin: 0;
}
.tournament-token-tweak {
  margin-left: 15px;
}
.submit.tournaments-panel-btn {
  margin-top: 10px;
}
.tournament-players-tab.tab-logic {
  margin: -14px -10px -10px;
}
.entryheader-item {
  float: left;
  font-weight: bold;
  padding: 16px 0 0;
}
.entryheader-num {
  display: table;
  float: left;
  height: 20px;
}
.entryheader-dealer {
  padding: 5px 0 11px 15px;
}
.dealer-input select {
  margin-bottom: 10px;
  margin-right: 5px;
  width: 70% !important;
}
.dealer-time-input-max {
  max-width: 110px;
}
.dealer-col .card-block {
  padding-bottom: 0;
}

.dealer-col .card-footer {
  margin-top: 0 !important;
}
.entryheader-points {
  padding-left: 6px;
}
.tournaments-financials-row {
}
.dealer-input {
  clear: left;
}
.alert-fixed {
  position: fixed;
  top: 0;
  z-index: 10000;
  width: 98%;
  left: 0;
  margin: 1%;
}
#divSearchMembers {
  background: #3e454c none repeat scroll 0 0 !important;
  border: medium none !important;
  -webkit-box-shadow: 0 5px 15px -10px #000 inset;
  box-shadow: 0 5px 15px -10px #000 inset;
  left: inherit !important;
  max-width: 400px;
  position: fixed !important;
  right: 0 !important;
  top: 55px !important;
  width: 100%;
  z-index: 10000;
}
#divSearchMembers select {
  width: 100% !important;
  font-size: 15px !important;
  padding: 5px 10px;
}
#divSearchMembers .btn {
  margin: 10px 0;
  width: 100px;
}
.tournaments-search-title {
  color: #fff;
  font-size: 19px;
  margin: 10px 0;
}
.inputheader.payment-type {
  margin-bottom: 12px;
}
.row.tournamentedit-mobile-row {
  width: 100%;
}
.tournaments-card .mat-tab-header {
  background: #fcfcfc;
  border-radius: 5px;
  padding-top: 12px;
  padding-left: 7px;
  margin: -2px 7px 8px;
  -webkit-box-shadow: 0 0 25px -10px #3b3e6b;
  box-shadow: 0 0 25px -10px #3b3e6b;
}
.tournaments-card .mat-tab-label {
  background: #3b3e6b !important;
  margin-right: 3px;
  color: #fff !important;
  border-radius: 3px 3px 0 0 !important;
}
.tournaments-card .mat-tab-label:focus {
  margin-right: 3px;
  color: #fff !important;
  border-radius: 3px 3px 0 0 !important;
}
.tournaments-card .mat-tab-label-active {
  background: #3b3e6b !important;
  opacity: 1;
  color: #fff !important;
  border-radius: 3px 3px 0 0 !important;
}
.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
  background: #3b3e6b !important;
}
.recap-display-logic {
  background: rgba(231, 231, 235, 0.85);
  padding: 15px;
  margin: -15px -15px -20px;
  font-family: roboto;
  font-size: 16px;
}
.recap-display-title {
  font-size: 19px;
  font-weight: 700;
}
.recap-display-description {
  float: right;
  line-height: 35px;
}
.action-copyrecap-button:after {
  content: 'Save & Copy';
}
.action-copyrecap-button.copied {
  color: #4caf50;
}
.action-copyrecap-button.copied:after {
  content: 'Copied!';
}
.nonpayment-section {
  background: rgba(231, 231, 235, 0.85) none repeat scroll 0 0;
  padding: 15px 0 8px;
  margin: -16px -125px 0 9px;
}
.multi-break {
  white-space: pre-line;
}
@-webkit-keyframes tabalert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(15);
    transform: scale(15);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes tabalert {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.tabicon {
  margin-left: 3px;
  color: #ff9800;
  font-size: 12px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.tab-complete.tabicon {
  color: #4caf50;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-animation: tabalert 0.5s ease;
  animation: tabalert 0.5s ease;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}
/***************
zfilter Responsive
****************/
@media screen and (max-width: 1630px) {
}
@media screen and (max-width: 767px) {
  #divSearchMembers {
    top: 44px !important;
  }
}
@media screen and (max-width: 560px) {
  .col-sm-6.mobile-payout {
    margin-top: 15px;
  }
  .col-xs-6.mobile-payout-left {
    padding-left: 0;
  }
  .total-row-title {
    text-align: center;
    font-size: 19px;
    margin-top: 0;
  }
  .tournamentedit-mobile-row {
    margin: 5px 0 0 -5px;
  }
  .tournaments-card .mat-tab-label {
    padding: 0 15px !important;
  }
}

/***********************************************************************************************************************
zfilter ADMIN Staff
************************************************************************************************************************/
.loginas {
  background: #f44336 !important;
  color: #fff !important;
  line-height: 46px !important;
}
.loginas-title {
  font-weight: bold;
  font-size: 18px;
  margin: 30px 0 5px;
}
/***********************************************************************************************************************
zfilter ADMIN Venues
************************************************************************************************************************/
.staff-payout-input {
  border: 2px solid #3b3e6b;
  width: 100%;
}
.staff-payout-logic {
  border-bottom: 1px solid #e5e5e5;
  display: table;
  padding: 20px 8px;
  width: 100%;
  position: relative;
}
.admin-panel-addremove {
  margin: 5px 0 11px;
  width: 100%;
}
.staff-items-logic {
  border-bottom: 1px solid #e5e5e5;
  padding: 30px 8px 25px;
  position: relative;
}
.staff-item-input {
  border: 2px solid #3b3e6b;
  width: 100%;
}
.select-addremove option {
  padding: 5px 10px;
  background: #3b3e6b !important;
  color: #fff;
  border-radius: 3px;
  margin-bottom: 3px;
}
.staff-payout-logic:last-child {
  border-bottom: medium none;
}
.staff-items-logic:last-child {
  border-bottom: medium none;
}
.position-remove {
  position: absolute;
  right: 9px;
  top: 7px;
}

.position-remove .mat-icon {
  font-size: 30px;
  color: #3b3e6b;
}
.season-action-btn {
  width: 100%;
  margin: 10px 0 13px !important;
}

/***********************************************************************************************************************
zfilter ADMIN Scoring
************************************************************************************************************************/
.editpanel-scoring .gridview-logic {
  padding: 0;
}
.editpanel-scoring .card-block {
  padding-bottom: 0;
}
.apc-league-scoring-h1 {
  font-size: 20px;
  color: #3b3e6b;
  font-weight: 700;
}
.apc-league-scoring-h2 {
  font-size: 16px;
  font-weight: 700;
  margin: 15px 0 10px;
}
.apc-league-scoring-logic p {
  margin-bottom: 10px;
}
.apc-league-scoring-h3 {
  font-weight: 700;
  margin: 10px 0;
}
.apc-league-scoring-logic li {
  font-weight: 700;
}
.apc-league-scoring-h4 {
  margin-bottom: 10px;
}
/***********************************************************************************************************************
zfilter ADMIN Events
************************************************************************************************************************/
.criteria-row {
  margin-bottom: 10px;
}
.grid-events .copy-to-clipboard {
  width: auto;
  margin: 0;
}
.copied-message {
  background: #4caf50 !important;
  color: #ffffff;
  padding: 3px;
  font-size: 12px;
  position: absolute;
  top: 3px;
  width: 100%;
  border-radius: 3px 0 0 3px !important;
  text-align: center;
  min-width: 100px;
  font-weight: 700;
}

/***********************************************************************************************************************
zfilter ADMIN Ads
************************************************************************************************************************/
.ads-message {
  clear: left;
  margin: 0;
  padding: 0;
}
.ads-message-save .btn {
  margin-top: 10px;
  width: 140px;
}
.league-ads-card .gridRow img {
  width: auto !important;
  height: auto !important;
}
@media screen and (max-width: 1600px) {
  .league-ads-card .gridRow img {
    max-width: 750px;
  }
}
@media screen and (max-width: 1400px) {
  .league-ads-card .gridRow img {
    max-width: 530px;
  }
}
@media screen and (max-width: 1000px) {
  .league-ads-card .gridRow img {
    max-width: 370px;
  }
}
@media screen and (max-width: 850px) {
  .league-ads-card .gridRow img {
    max-width: 320px;
  }
}
@media screen and (max-width: 767px) {
  .league-ads-card .gridRow img {
    max-width: 280px;
  }
}

/***********************************************************************************************************************
zfilter Admin Administration
************************************************************************************************************************/
.system-stat-block .inputheader {
  font-weight: bold;
  font-size: 15px;
}

.system-stat-block span {
  font-size: 60px !important;
  color: #3b3e6b;
}

.system-stat-block .panelrow {
  float: left;
  display: table;
  width: 33.33%;
  padding-left: 8px;
}

@media screen and (max-width: 1000px) {
  .system-stat-block .panelrow {
    width: 100%;
  }
}
/***********************************************************************************************************************
zfilter Tabs
************************************************************************************************************************/
.tabs {
  float: none;
  list-style: none;
  padding: 0;
  margin: 0 auto;
}
.tabs:after {
  content: '';
  display: table;
  clear: both;
}
.tabs .tab-header-radio {
  display: none;
}
.tabs .tournament-tab-label {
  background: #ddd none repeat scroll 0 0;
  border-bottom: 1px solid #828382;
  -webkit-box-shadow: 0 -8px 20px -17px inset;
  box-shadow: 0 -8px 20px -17px inset;
  color: #666;
  cursor: pointer;
  float: left;
  font-size: 25px;
  font-weight: normal;
  line-height: 2;
  padding: 5px 0;
  text-align: center;
  text-decoration: none;
  width: 25%;
  -webkit-transition: all 0.5s; /* Safari 3.1 to 6.0 */
  transition: all 0.5s;
}
.tabs label span {
  display: none;
  font-size: 12px;
}
.tabs label i {
  padding: 5px;
  margin-right: 0;
}
.tabs label:hover {
  color: #3b3e6b;
}
.tab-content {
  display: none;
  width: 100%;
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #ffffff;
}
.tabs [id^='tab']:checked + label {
  background: #fff none repeat scroll 0 0;
  border-bottom: 1px solid #fff;
  -webkit-box-shadow: 0 -5px, 0 15px 9px -8px #eee inset;
  box-shadow: 0 -5px, 0 15px 9px -8px #eee inset;
  color: #3b3e6b;
  z-index: 87;
}
#tab1:checked ~ #tab-content1,
#tab2:checked ~ #tab-content2,
#tab3:checked ~ #tab-content3,
#tab4:checked ~ #tab-content4 {
  display: block;
}
.tab-logic .switch {
  float: left;
  margin: 15px 7px 45px;
}
.tab-content {
  padding: 15px 13px 0;
}
@media (min-width: 960px) {
  .tabs i {
    padding: 5px;
    margin-right: 10px;
  }
  .tabs label span {
    display: inline-block;
    font-size: 20px;
  }
  .tab-content h3 {
    display: none;
  }
  .tabs {
    margin: 0 auto;
  }
}
/***********************************************************************************************************************
zfilter ADMIN Help
************************************************************************************************************************/
.help-logic p {
  font-size: 16px;
  margin-bottom: 15px;
}
/***********************************************************************************************************************
zfilter ADMIN Reports
************************************************************************************************************************/
.report-setup .btn {
  max-width: 300px;
}
.report-column {
  width: 50%;
  float: left;
}

.report-header {
  background: #3b3e6b;
  display: table;
  width: 100%;
  padding: 20px 10px 100px;
  height: auto;
  min-height: 100vh;
}

.report-column-padding {
  margin: 10px;
  background: #fff;
  padding: 10px 15px;
}
.report-title {
  font-size: 17px;
  font-weight: bold;
  color: #3b3e6b;
}
.report-item-text {
  font-size: 20px;
  line-height: 27px;
  color: #4caf50;
}
.delete-report {
  cursor: pointer;
}
.report_footer_row td {
  font-size: 18px;
}
/***********************************************************************************************************************
zfilter ADMIN Leaderboards
************************************************************************************************************************/
.embedcollapse {
  background: #3b3e6b;
  border-radius: 3px 3px 0 0;
  clear: left;
  color: #fff;
  float: left;
  margin: 10px 5px 0 0;
  padding: 2px 10px;
}
.league-card-title {
  margin: 0 0 15px;
  padding: 0;
}
.menumessage {
  color: #000;
  font-size: 20px;
  padding: 1px 0 2px;
}
.lb-embed textarea {
  height: auto;
  font-size: 16px;
}
.lb-embed textarea:focus {
  background: #ddd none repeat scroll 0 0;
}
.lb-embed {
  margin: 10px 0 10px;
}
.leaderboard-card-title {
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.players .player img {
  max-width: 275px;
  width: 100%;
}
.btn-viewleaderboard {
  cursor: pointer;
}
.action-copyleaderboard-button:after {
  content: 'Copy';
}
.action-copyleaderboard-button.copied {
  color: #4caf50;
}
.action-copyleaderboard-button.copied:after {
  content: 'Copied!';
}
.leaderboard-mat-panel {
  margin-bottom: 20px !important;
  border-radius: 3px;
  overflow: hidden;
}
.isopen .leaderboard-content {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: auto;
  max-height: max-content;
}
.leaderboard-content {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  max-height: max-content;
  height: 0;
}
.collapse-button::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f078';
}
.leaderboard-card.isopen .collapse-button::before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f077';
}
.card.leaderboard-card {
  border: none !important;
}
.leaderboard-card .card-header {
  cursor: pointer;
}

.card.leaderboard-card.isopen {
  border-bottom: 1px solid #ffffff !important;
}

.panelrow.leaderboard-column {
  float: left;
  width: 48%;
  margin-right: 4%;
  position: relative;
}
.panelrow.leaderboard-column:nth-child(2) {
  margin-right: 0;
}

.leaderboard-card-selection-logic .mat-form-field-label {
}
.leaderboard-card-selection-logic .mat-hint {
}
.leaderboard-card-selection-logic .mat-select-value {
  color: inherit !important;
}
.leaderboard-card-selection-logic .mat-select-arrow {
  color: inherit !important;
  opacity: 0.7;
}
.leaderboard-details-btn {
  background: #1e1f46;
  border: none;
  font-family: arial;
  color: #fff;
  padding: 7px 20px 8px;
  position: absolute;
  top: 50px;
  right: 7px;
  font-size: 13px;
  cursor: pointer;
  border-radius: 0 0 3px 3px;
}
.leaderboard-user-actions-logic a {
  background: #1e1f46;
  width: 100%;
  text-align: center;
  float: right;
  border: none;
  margin: 5px 0 0;
  padding: 5px 0;
  display: table;
  color: #fff;
  line-height: 19px;
  font-size: 13px;
  border-radius: 3px;
}
.leaderboard-card-selection-logic .rowadjust {
  margin-bottom: -12px;
  margin-top: -22px;
}
.leaderboard-card-selection-logic {
  background: rgba(255, 255, 255, 0.08);
  display: flow-root;
  padding: 15px 6px;
  margin: -10px -15px -15px;
  clear: left;
  height: auto;
}

/***********************************************************************************************************************
zfilter Reports
************************************************************************************************************************/
.reports-tabs ul {
  width: 100%;
}
.reports-tabs li a.selected {
  background: #eeeeee none repeat scroll 0 0;
}
.reports-tabs li a.highlighted {
  background: #cccccc none repeat scroll 0 0;
}
.reports-tabs ul {
  width: 100% !important;
}
.level1.dropdown-item a {
  width: 100%;
}
.level1 li {
  width: 100%;
}
.level1 li a {
  padding: 7px 11px !important;
}
.report-setup {
  margin-bottom: 12px;
  margin-top: 12px;
}
.breakdown-card .card-block {
  padding: 20px;
}

.breakdown-card table tr {
  border-bottom: 1px solid #e4e4e4;
}

.breakdown-card table tr:last-child {
  border: none;
}
.breakdown-card:nth-child(3n + 2) {
  clear: left;
}
.report-heading {
  background: #eee none repeat scroll 0 0;
  font-size: 20px;
  margin: 20px 0;
  padding: 30px;
  text-align: center;
  width: 100%;
}
.breakdown-card .card-header {
  font-size: 13px;
}
/*  Reports   */

.report_item_header {
  font-size: 20px;
  font-weight: bold;
  line-height: 45px;
  margin-left: 15px;
}
.report_title {
  font-size: 16px;
  font-weight: bold;
}
.report_body {
  margin: 20px 15px;
}
.report_header_row > td {
  border-bottom: 1px solid #9194bf;
  padding-top: 6px;
  padding-bottom: 6px;
}
.report_footer_row > td {
  border-top: 1px solid #9194bf;
}
.report_item_row td,
.report_footer_row td {
  padding-top: 3px;
  padding-bottom: 3px;
}
.report_rows td {
  padding-left: 15px;
  padding-right: 15px;
}
.payroll_main {
  margin: -15px;
}
.report-heading-two {
  font-size: 16px;
  font-weight: bold;
}
.report-header-title {
  font-size: 28px;
  font-weight: 700;
}
.report-details-card .card-block {
  padding: 15px;
}
/***********************************************************************************************************************
zfilter QuartzGrid
************************************************************************************************************************/

.gridRow > td {
  border: medium none;
}
.gridview-logic table tr:nth-child(1) {
  background: rgba(48, 51, 97, 0.25);
}

.gridview-logic table thead tr {
  background: #ffffff !important;
  background: -webkit-linear-gradient(top, #ffffff 0%, #f4f4f4 100%) !important;
  background: linear-gradient(to bottom, #ffffff 0%, #f4f4f4 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f4f4f4',GradientType=0 ) !important;
}
.gridview-logic .table thead th {
  border-bottom: none;
  cursor: pointer;
}
.gridview-logic table tr:nth-child(1) td {
  padding: 3px;
}
.gridview-logic .form-control {
  width: 100% !important;
  border: none;
  font-size: 16px;
}
.gridview-logic .form-control::placeholder {
  font-size: 16px;
}
.gridview-logic td a {
  width: 100%;
  color: #3b3e6b;
}
.gridview-logic td svg {
  color: #3b3e6b;
}
.table-edit-btn {
    color: #3b3e6b;
    text-align: center;
    line-height: 24px;
    margin-right: 5px;
}
.gridview-sort {
  color: #3b3e6b;
  font-weight: bold;
}
.table-yes {
  color: #4caf50;
}
.table-no {
  color: #f44336;
}
.page-item.active .page-link {
  background-color: #3b3e6b;
  border-color: #3b3e6b;
}

.mat-paginator-page-goto {
  width: 70px;
  float: left;
}

.mat-paginator-page-goto .mat-form-field {
  width: 60px;
}
.venue-details-table table {
  display: block;
  overflow-x: auto;
  border: none;
}
.venue-details-table table th:nth-child(2) {
  min-width: 160px;
  text-align: left;
  padding-left: 10px;
}
.venue-details-table table th:nth-child(1) {
  min-width: 60px;
}
.venue-details-table table th {
  background: rgb(50, 51, 102);
  border: none;
  color: #fff;
  font-weight: bold;
  padding: 7px 0px;
  min-width: 120px;
  text-align: center;
  cursor: pointer;
}
.venue-details-table table td {
  text-align: center;
}
.venue-details-table table td:nth-child(2) {
  text-align: left;
}

/***********************************************************************************************************************
zfilter Table Styling
************************************************************************************************************************/

/* Tournaments Open */
.grid-tournaments th:nth-child(1) {
  width: 30%;
}
.grid-tournaments th:nth-child(2) {
  width: 12%;
  text-align: center;
}
.grid-tournaments th:nth-child(3) {
  width: 20%;
  text-align: center;
}
.grid-tournaments th:nth-child(4) {
  text-align: center;
  width: 20%;
}
.grid-tournaments th:nth-child(5) {
  text-align: center;
  width: 10%;
}
.grid-tournaments th:nth-child(6) {
  text-align: center;
  width: 8%;
}
.grid-tournaments td:nth-child(2) {
  text-align: center;
}
.grid-tournaments td:nth-child(3) {
  text-align: center;
}
.grid-tournaments td:nth-child(4) {
  text-align: center;
}
.grid-tournaments td:nth-child(5) {
  text-align: center;
}
.grid-tournaments td:nth-child(6) {
  text-align: center;
}
/* Tournaments Close */

/* Players Open */
.grid-players th:nth-child(1) {
  width: 60%;
}
.grid-players th:nth-child(2) {
  width: 20%;
  text-align: center;
}
.grid-players td:nth-child(2) {
  text-align: center;
}
.grid-players th:nth-child(3) {
  width: 10%;
  text-align: center;
}
.grid-players td:nth-child(3) {
  text-align: center;
}
.grid-players th:nth-child(4) {
  width: 10%;
  text-align: center;
}
.grid-players td:nth-child(4) {
  text-align: center;
}
/* Players Close */

/* Dealers Open */

/* Dealers Close */

/* Staff Open */
.grid-staff th:nth-child(1) {
  width: 20%;
}
.grid-staff th:nth-child(2) {
  width: 20%;
}
.grid-staff th:nth-child(3) {
  width: 30%;
  text-align: center;
}
.grid-staff th:nth-child(4) {
  width: 30%;
  text-align: center;
}
.grid-staff td:nth-child(3) {
  text-align: center;
}
.grid-staff td:nth-child(4) {
  text-align: center;
}
/* Staff Close */

/* League Activity Open */
.grid-activity th:nth-child(1) {
  width: 10%;
  text-align: center;
}
.grid-activity th:nth-child(2) {
  width: 90%;
}
.grid-activity td:nth-child(1) {
  text-align: center;
}
/* League Activity Close */

/* Venues Open */
.grid-venues th:nth-child(1) {
  width: 60%;
}
.grid-venues th:nth-child(2) {
  width: 15%;
  text-align: center;
}
.grid-venues th:nth-child(3) {
  width: 15%;
  text-align: center;
}
.grid-venues th:nth-child(4) {
  width: 10%;
  text-align: center;
}

.grid-venues td:nth-child(2) {
  text-align: center;
}
.grid-venues td:nth-child(3) {
  text-align: center;
}
.grid-venues td:nth-child(4) {
  text-align: center;
}
/* Venues Close */

/* Seasons Open */
.grid-seasons th:nth-child(1) {
  width: 40%;
}
.grid-seasons th:nth-child(2) {
  width: 13%;
  text-align: center;
}
.grid-seasons th:nth-child(3) {
  width: 13%;
  text-align: center;
}
.grid-seasons th:nth-child(4) {
  width: 13%;
  text-align: center;
}
.grid-seasons th:nth-child(5) {
  width: 11%;
  text-align: center;
}
.grid-seasons th:nth-child(6) {
  width: 10%;
  text-align: center;
}

.grid-seasons td:nth-child(2) {
  text-align: center;
}
.grid-seasons td:nth-child(3) {
  text-align: center;
}
.grid-seasons td:nth-child(4) {
  text-align: center;
}
.grid-seasons td:nth-child(5) {
  text-align: center;
}
.grid-seasons td:nth-child(6) {
  text-align: center;
}

/* Seasons Close */

/* Scoring Open */
.grid-scoring th:nth-child(1) {
  width: 60%;
}
.grid-scoring th:nth-child(2) {
  width: 15%;
  text-align: center;
}
.grid-scoring th:nth-child(3) {
  width: 15%;
  text-align: center;
}
.grid-scoring th:nth-child(4) {
  width: 10%;
  text-align: center;
}
.grid-scoring td:nth-child(2) {
  text-align: center;
}
.grid-scoring td:nth-child(3) {
  text-align: center;
}
.grid-scoring td:nth-child(4) {
  text-align: center;
}
/* Scoring Close */

/* Reports Open */
.grid-reports th:nth-child(1) {
  width: 3%;
  text-align: center;
}
.grid-reports th:nth-child(2) {
  width: 15%;
  text-align: center;
}
.grid-reports th:nth-child(3) {
  width: 8%;
  text-align: center;
}
.grid-reports th:nth-child(4) {
  width: 7%;
  text-align: center;
}
.grid-reports th:nth-child(5) {
  width: 7%;
  text-align: center;
}
.grid-reports th:nth-child(6) {
  width: 17%;
  text-align: center;
}
.grid-reports th:nth-child(7) {
  width: 10%;
  text-align: center;
}
.grid-reports th:nth-child(8) {
  width: 10%;
  text-align: center;
}
.grid-reports th:nth-child(9) {
  width: 10%;
  text-align: center;
}
.grid-reports th:nth-child(10) {
  width: 8%;
  text-align: center;
}
.grid-reports th:nth-child(11) {
  width: 5%;
  text-align: center;
}

.grid-reports td:nth-child(1) {
  width: 3%;
  text-align: center;
}
.grid-reports td:nth-child(2) {
  width: 15%;
  text-align: center;
}
.grid-reports td:nth-child(3) {
  width: 8%;
  text-align: center;
}
.grid-reports td:nth-child(4) {
  width: 7%;
  text-align: center;
}
.grid-reports td:nth-child(5) {
  width: 7%;
  text-align: center;
}
.grid-reports td:nth-child(6) {
  width: 17%;
  text-align: center;
}
.grid-reports td:nth-child(7) {
  width: 10%;
  text-align: center;
}
.grid-reports td:nth-child(8) {
  width: 10%;
  text-align: center;
}
.grid-reports td:nth-child(9) {
  width: 10%;
  text-align: center;
}
.grid-reports td:nth-child(10) {
  width: 8%;
  text-align: center;
}
.grid-reports td:nth-child(11) {
  width: 5%;
  text-align: center;
}
/* Reports Close */

/* Events Open */
.grid-events th:nth-child(1) {
  width: 29%;
}
.grid-events th:nth-child(2) {
  width: 12%;
  text-align: center;
}
.grid-events th:nth-child(3) {
  width: 12%;
  text-align: center;
}
.grid-events th:nth-child(4) {
  width: 12%;
  text-align: center;
}
.grid-events th:nth-child(5) {
  width: 27%;
  text-align: center;
}
.grid-events th:nth-child(6) {
  width: 8%;
  text-align: center;
}

.grid-events td:nth-child(1) {
}
.grid-events td:nth-child(2) {
  text-align: center;
}
.grid-events td:nth-child(3) {
  text-align: center;
}
.grid-events td:nth-child(4) {
  text-align: center;
}
.grid-events td:nth-child(5) {
}
.grid-events td:nth-child(6) {
  text-align: center;
}
/* Events Close */
/* Ads Open */
.grid-ads th:nth-child(1) {
  width: 25%;
}
.grid-ads th:nth-child(2) {
  width: 55%;
}
.grid-ads th:nth-child(3) {
  width: 10%;
  text-align: center;
}
.grid-ads th:nth-child(4) {
  width: 10%;
  text-align: center;
}

.grid-ads td:nth-child(1) {
}
.grid-ads td:nth-child(2) {
}
.grid-ads td:nth-child(3) {
  text-align: center;
}
.grid-ads td:nth-child(4) {
  text-align: center;
}
/* Ads Close */

/* Player Activity Open */
.grid-playeractivity th:nth-child(1) {
  width: 30%;
}
.grid-playeractivity th:nth-child(2) {
  width: 18%;
  text-align: center;
}
.grid-playeractivity th:nth-child(3) {
  width: 12%;
  text-align: center;
}
.grid-playeractivity th:nth-child(4) {
  width: 12%;
  text-align: center;
}
.grid-playeractivity th:nth-child(5) {
  width: 18%;
  text-align: center;
}
.grid-playeractivity th:nth-child(6) {
  width: 10%;
  text-align: center;
}

.grid-playeractivity td:nth-child(1) {
}
.grid-playeractivity td:nth-child(2) {
  text-align: center;
}
.grid-playeractivity td:nth-child(3) {
  text-align: center;
}
.grid-playeractivity td:nth-child(4) {
  text-align: center;
}
.grid-playeractivity td:nth-child(5) {
  text-align: center;
}
.grid-playeractivity td:nth-child(6) {
  text-align: center;
}

/* Player Activity Close */

/* Dealer Activity Open */
.grid-dealeractivity th:nth-child(1) {
  width: 30%;
}
.grid-dealeractivity th:nth-child(2) {
  width: 18%;
  text-align: center;
}
.grid-dealeractivity th:nth-child(3) {
  width: 12%;
  text-align: center;
}
.grid-dealeractivity th:nth-child(4) {
  width: 12%;
  text-align: center;
}
.grid-dealeractivity th:nth-child(5) {
  width: 18%;
  text-align: center;
}
.grid-dealeractivity th:nth-child(6) {
  width: 10%;
  text-align: center;
}

.grid-dealeractivity td:nth-child(1) {
}
.grid-dealeractivity td:nth-child(2) {
  text-align: center;
}
.grid-dealeractivity td:nth-child(3) {
  text-align: center;
}
.grid-dealeractivity td:nth-child(4) {
  text-align: center;
}
.grid-dealeractivity td:nth-child(5) {
  text-align: center;
}
.grid-dealeractivity td:nth-child(6) {
  text-align: center;
}

/* Dealer Activity Close */
/***********************************************************************************************************************
zfilter Gridview Editor
************************************************************************************************************************/
.gridview-editor-logic {
  background: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.gridview-editor-content {
  padding-top: 12px;
}
.tournament-players-table-logic td {
  padding: 10px 0 !important;
}
.tournament-players-tab.tab-logic {
  margin: -14px -7px -10px;
  width: calc(100% + 14px);
  display: table;
}
.gridview-editor-btn {
    background: #3b3e6b!important;
    color: #fff!important;
    margin-right: 4px !important;
}
.editor-footer {
  margin-top: -2px !important;
  border: none;
}
.editpanel-column.left-edit {
  padding-right: 7% !important;
  padding-left: 0 !important;
}
.date-split-row {
  margin-left: -5px;
}

.gridview-editor-btn[disabled] {
  opacity: 0.7;
}
.footer-btn-left {
  float: left;
  width: 140px;
}
.footer-btn-right {
  float: right;
  width: 140px;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #3b3e6b !important;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(59, 62, 107, 0.41) !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3b3e6b !important;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #3b3e6b !important;
}
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #44488c !important;
}
.mat-tab-label {
  color: #3b3e6b !important;
  font-weight: bold !important;
  border-radius: 3px 3px 0 0 !important;
}
.mat-form-field-label {
  font-size: 16px !important;
}
.card-footer mat-icon {
  float: left;
  margin: -2px 3px 0 -5px;
  font-size: 23px;
  line-height: 38px;
}
.mat-tab-list {
  margin: 0 5px !important;
}
.bs-datepicker-head {
  background-color: #3b3e6b !important;
}
.theme-green .bs-datepicker-body table td span.selected,
.theme-green .bs-datepicker-body table td.selected span,
.theme-green .bs-datepicker-body table td span[class*='select-']:after,
.theme-green .bs-datepicker-body table td[class*='select-'] span:after {
  background-color: #3b3e6b !important;
}

@media screen and (max-width: 992px) {
  .editpanel-column.left-edit {
    padding-right: 0 !important;
  }
  .left-edit {
    margin-bottom: 10px;
  }
}
/***********************************************************************************************************************
zfilter Login
************************************************************************************************************************/
#member_login {
  min-width: 275px;
}
.login-label {
  font-size: 16px;
  text-align: left;
  width: 100%;
  font-weight: bold !important;
  padding-left: 0;
  margin: 5px 0 -2px 0;
}
.signupinputlogic .login-label {
  color: #000000;
}
.signupinputlogic .login-label i {
  color: #262651 !important;
}
.card-header.login-selection {
  border-bottom: 1px solid #323367;
  padding: 10px 10px 0 !important;
}
.login-selection-link {
  border-left: 1px solid #ddd;
  border-radius: 3px 3px 0 0;
  border-right: 1px solid #ddd;
  border-top: 1px solid #ddd;
  display: table;
  font-family: arvoregular;
  font-size: 12px;
  margin: 0;
  padding: 15px;
  width: 100%;
  color: #323367 !important;
  background: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, from(#ffffff), to(#f5f5f5));
  background: linear-gradient(to bottom, #ffffff 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f5f5f5',GradientType=0 );
}
.login-selection-link:hover {
  background: #ddd;
}
.current .login-selection-link {
  background: #323367;
  color: #fff !important;
  border-color: #323367;
}
.login-selection-button:nth-child(1) {
  padding-left: 0;
}
.login-selection-button:nth-child(2) {
  padding-right: 0;
}
.plblogin {
  background: transparent none repeat scroll 0 0 !important;
  margin: auto;
  max-width: 350px;
  padding: 0 5px;
  width: 100%;
}
.plbloginlogic {
  background: #cfcfcf;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #cfcfcf),
    color-stop(100%, #717171)
  );
  background: linear-gradient(to bottom, #cfcfcf 0, #717171 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cfcfcf', endColorstr='#717171',GradientType=0 );
  border: 1px solid #000000;
  float: none !important;
  height: 380px;
  margin: auto;
  position: relative;
  top: 25%;
  width: 780px !important;
}
.plbrelogin {
  margin-top: 12%;
}
.loginleftlogic {
  float: left;
  width: 377px;
}
.loginlogo {
  height: 210px;
  margin-top: -20px;
  text-align: center;
}
.loginlogo img {
  max-width: 220px;
}
/*.loginprivacy {
	color: #414141;
	font-family: arvoregular;
	text-align: center;
	-ms-text-shadow: 1px 1px 0 #ABABAB;
	text-shadow: 1px 1px 0 #ABABAB;
}*/
.loginprivacy {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
  color: #fff;
  font-family: arvoregular;
  font-size: 11px;
  margin: -10px 0 0 !important;
  text-align: center;
}
.loginprivacy a {
  color: #fff;
  text-decoration: underline !important;
}
.loginprivacy a:hover {
  text-decoration: none !important;
}
.forgetpass {
  font-family: arvoregular;
  font-size: 12px;
  margin: 3px 0 15px;
  text-align: center;
}
.create-account {
  font-family: arvoregular;
  font-size: 12px;
  margin: 3px 0 15px;
  text-align: center;
}
.create-account > a {
  text-decoration: underline !important;
}
.create-account > a:hover {
  text-decoration: none !important;
}
.plbfree {
  color: #8f2c01;
  font-family: arvoitalic;
  font-style: italic;
  margin: auto;
}
.plbfree a {
  color: #8f2c01;
  text-decoration: underline !important;
}
.plbfree a:hover {
  text-decoration: none !important;
}
.signupcard .card-footer {
  border-color: #b14e00;
}
.signupcard .card-header {
  border-color: #b14e00;
  color: #ffffff !important;
}
.signupstage {
  background: #fa7001;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fa7001),
    color-stop(100%, #ab4c00)
  );
  background: linear-gradient(to bottom, #fa7001 0, #ab4c00 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fa7001', endColorstr='#ab4c00',GradientType=0 );
  border: 1px solid #e95c0b;
  border-radius: 2px 2px 2px 2px;
  -webkit-box-shadow: 0 0 3px #d4542b;
  box-shadow: 0 0 3px #d4542b;
  display: inline-block;
  height: 62px;
}
.signupbox {
  border-color: #e95c0b;
}
.loginbtn {
  background: #323367 none repeat scroll 0 0;
  border: medium none;
  color: #ffffff !important;
  border-radius: 3px;
  cursor: pointer;
  font-family: arvoregular;
  font-size: 20px;
  padding: 12px 0;
  text-transform: uppercase;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  margin-top: 11px;
}
.loginbtn:hover {
  background: none repeat scroll 0 0 #1b2346;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.admin {
  background: #454d70 none repeat scroll 0 0;
  border-radius: 0.25rem;
  float: left;
  text-decoration: none;
}
.logout {
  background: none repeat scroll 0 0 darkred;
}
.player-login-join {
  font-family: arvoregular;
  font-size: 12px;
}
.login-label i {
  margin-right: 4px;
}
.signup-info-logic {
  font-size: 12px;
  margin-bottom: 24px;
}
.signup-info-heading {
  clear: left;
  color: #12264e;
  display: table;
  font-weight: bold;
  margin: 5px 0;
  width: 100%;
  text-align: center;
}
.signup-info-content {
  margin-bottom: 0;
  font-size: 11px;
  font-family: arial;
  margin: 7px 0 0;
}
.signupinputlogic {
  padding-top: 5px;
}
.loginsignup {
  font-family: Roboto !important;
  font-weight: 300 !important;
  font-size: 22px !important;
  line-height: 45px !important;
}
.error-hint {
  color: #e53935 !important;
}
.signupinputlogic .panelrow {
  margin: -5px 0 7px !important;
}
.signupinputlogic .formgroup {
  margin: 0px 0 5px;
  display: table;
  width: 100%;
}
.verify-help-list {
  padding: 15px;
}

.list_number {
  font-weight: bold;
  font-size: 20px;
}

.verify-help-item {
  margin-bottom: 12px;
  line-height: 20px;
  font-size: 14px;
}
/***********************************************************************************************************************
zfilter Edit Profile
************************************************************************************************************************/
.editprofile-logic {
  margin: auto;
}
.customize-profile-action-logic {
  left: 0;
  margin: 0;
  padding: 9px !important;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background: rgba(255, 255, 255, 0.95) none repeat scroll 0 0;
  -webkit-box-shadow: 0 0 10px -7px #000;
  box-shadow: 0 0 10px -7px #000;
  color: #ffffff;
  float: left;
}
.profile-edit-logic {
  margin-top: 72px;
}
.customize-profile-name {
  font-family: 'Roboto', sans-serif;
  font-size: 32px;
}
.profile-upload {
  max-width: 100%;
  width: 100%;
}
#color_pickers input {
  border: 1px solid #3b3e6b !important;
  height: 30px !important;
  margin-right: 10px;
}
#color_pickers > div input {
  border: none !important;
}
#color_pickers div input {
  border: medium none !important;
  width: 150px;
}
.verify-howto {
  border-bottom: 1px solid #eee;
  font-size: 12px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-align: left;
}
.verify-howto ul {
  padding-top: 12px;
}
/***********************************************************************************************************************
zfilter Customs
************************************************************************************************************************/
.datagrid-empty {
  font-size: 17px;
  margin: 13px;
}
.action-btn .fa-question-circle {
  margin-right: 4px;
}
#ctl00_lblInactiveNotice,
#ctl00_ContentPlaceHolder1_lblSystemMessage {
  background: #f2dede none repeat scroll 0 0;
  border-color: #ebcccc !important;
  border-radius: 4px;
  color: #a94442 !important;
  font-size: 13px !important;
  margin: -5px 0 15px !important;
  padding: 15px;
}
#ctl00_ContentPlaceHolder1_lblSystemMessage {
  background: #d6dc4c;
  color: #000 !important;
}
.card-playersignup #btnVenueDetail {
  width: 100%;
}
a[href^='tel'] {
  color: inherit;
  text-decoration: none;
}
.card-block {
  padding: 15px 8px 5px;
}
.card-block .alert {
  width: 100%;
  clear: left;
}
.scrollToTop {
  background: #3b3e6b none repeat scroll 0 0;
  border-radius: 0.25rem 0 0;
  bottom: 0;
  color: #fff !important;
  font-size: 25px;
  padding: 3px 13px;
  position: fixed;
  right: 0;
  z-index: 111;
}
.horizon-swiper .horizon-inner {
  cursor: pointer;
  text-align: left;
}
.ui-datepicker {
  font-size: 13px;
}
button[disabled],
html input[disabled],
select[disabled],
textarea[disabled] {
  color: #000;
}
.totalrow input {
  border-color: green;
  color: green !important;
  font-size: 18px;
  font-weight: bold;
  opacity: 1 !important;
  text-align: center;
}
/* Current Page Start */
.Dashboard .menu-dash {
  background: none repeat scroll 0 0 #181b20;
}
.Leaderboards .menu-leaderboards {
  background: none repeat scroll 0 0 #181b20;
}
.Tournaments .menu-tournaments {
  background: none repeat scroll 0 0 #181b20;
}
.Players .menu-players {
  background: none repeat scroll 0 0 #181b20;
}
.Staff .menu-staff {
  background: none repeat scroll 0 0 #181b20;
}
.Venues .menu-venues {
  background: none repeat scroll 0 0 #181b20;
}
.Seasons .menu-seasons {
  background: none repeat scroll 0 0 #181b20;
}
.Scoring .menu-scoring {
  background: none repeat scroll 0 0 #181b20;
}
.Events .menu-events {
  background: none repeat scroll 0 0 #181b20;
}
.Ads .menu-ads {
  background: none repeat scroll 0 0 #181b20;
}
.Reports .menu-reports {
  background: none repeat scroll 0 0 #181b20;
}
/* Current Page End */
.hide-element {
  display: none;
}
#popCalDiv {
  background-color: white;
  left: 30px !important;
  position: absolute;
  top: 153px !important;
  visibility: visible;
}
.secondmenuentry {
  border-radius: 0;
  margin: 0 !important;
  padding: 2px 10px;
}
.contentlogic {
  background: none repeat scroll 0 0 blue;
  margin: auto;
  max-width: 1140px;
}
.reponsive {
  width: 100%;
}
/* leader board edit */
.leaderboard-logic {
  width: 350px;
}
.leaderboardedit-lbMainRow > td {
  border: medium none !important;
}
.leaderboardedit-lbGridDefaultAltRow > td {
  border: medium none !important;
}
.leaderboardedit th {
  text-align: center;
}
.leaderboardedit-lbGridDefault tr:nth-child(2n + 1) {
  background: none repeat scroll 0 0 #1a1a1a !important;
}
.leaderboardedit-lbMainRow a {
  color: #ffffff !important;
  text-decoration: none;
}
.leaderboardedit-lbMainRow {
  background: none repeat scroll 0 0 #464646 !important;
  color: #ffffff !important;
  height: 30px;
}
.leaderboardedit-lbGridDefault {
  border: medium none;
  font-weight: normal !important;
  width: 350px !important;
}
#grdLeaderBoardEdit th {
  background: none repeat scroll 0 0 #1a1a1a !important;
  border: medium none !important;
  color: #ffffff !important;
}
#grdLeaderBoardEdit th a {
  color: #ffffff !important;
  text-decoration: none;
}
/*.lbHeader th {
	background: none repeat scroll 0 0 rgba(0, 0, 0, 0) !important;
	border: medium none !important;
}*/
.leaderboard-classic-logic {
  max-width: 300px;
}
.leaderboard-classic-logic th {
  background-color: #d7d7ce;
  border-color: #ccccc2;
  border-style: solid;
  border-width: 1px;
  color: #66665c;
}
.playerfilter-logic {
  background: none repeat scroll 0 0 #2c2c2c;
  width: 350px;
}
.playerfilter-select {
  height: 55px;
  padding: 19px 15px 5px !important;
  position: relative;
}
.playerfilter-select > span {
  color: #fff;
  float: right;
  font-family: alfa;
  font-size: 20px;
  line-height: 27px;
  position: absolute;
  right: 25px;
  text-align: right;
  top: 25px;
  width: 33%;
}
.playerfilter-heading {
  color: #fff;
  font-family: alfa;
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 5px;
}
.playerfilter-region {
  display: none;
}
.searchresetlogic {
  width: 100%;
}
.searchresetbtn {
  background: none repeat scroll 0 0 darkmagenta;
  color: #fff;
  display: table;
  font-size: 18px;
  line-height: 35px;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
#ddlVenue {
  max-width: 240px;
}
.playerfilter-search {
  background: none repeat scroll 0 0 #444;
  margin-top: 10px;
  padding: 15px;
  text-align: center;
}
#tr_ldrplayerSearch > input {
  margin: 0 !important;
  max-width: 308px;
  width: 100%;
}
.filterheader {
  color: #999;
  font-family: alfa;
  font-size: 25px;
  font-weight: normal;
  line-height: 28px;
  margin: 0 0 10px;
}
.filterheader i {
  font-size: 30px;
}
.btn.text-right {
  border-radius: 0 3px 0 0;
  float: right;
  padding: 15px 25px 16px;
  position: absolute;
  right: 0;
  top: 0;
}
.btn.text-right-half {
  border-radius: 0;
  float: right;
  padding: 7px 25px;
  position: absolute;
  right: 0;
  top: 0;
}
.level1.btn {
  padding: 0.7rem 1.3rem !important;
}
.btn-plbinfo {
  background-color: #282f5f;
  border-color: #151c4c;
  color: #fff;
  text-decoration: none;
  outline: none;
}
.btn-plbinfo.active,
.btn-plbinfo.focus,
.btn-plbinfo:active,
.btn-plbinfo:focus,
.btn-plbinfo:hover,
.open > .dropdown-toggle.btn-plbinfo {
  background-color: #0d1444;
  border-color: #0d1444;
  color: #fff;
  outline: none;
}
.btn.btn-plbinfo.ls-current {
  background: none repeat scroll 0 0 #0d1444;
  border-color: #0d1444;
  outline: none;
}
.tablerow-edit {
  clear: left;
  float: left;
  padding: 10px 0;
  width: 100%;
}
.tablecolumn-place {
  color: #272e5e;
  float: left;
  font-size: 30px;
  padding: 8px 0 0;
  text-align: center;
  width: 20%;
}
.numericText.scoring-amount {
  border: 2px solid #3b3e6b;
  width: 50%;
}
.tablecolumn-amount {
  width: 70%;
  float: left;
}
.tablecolumn-header {
  font-size: 18px;
  color: #272e5e;
  padding: 8px 0 0;
}
.tablerow-edit:nth-child(2n) {
  background: none repeat scroll 0 0 #eee;
}
.editpanel-updatescoring {
  margin-top: 20px;
  text-align: center;
}
.updatescoringbtn {
  font-size: 25px;
  padding: 15px 0;
  width: 40%;
}
.scoring-amount {
  font-size: 15px;
  text-align: center;
  width: 75px;
}
.editpanel-scoring-header {
  color: #272e5e;
  font-family: alfa;
  font-size: 30px;
  margin: 35px 0;
  text-align: center;
}
.editpanel-scoring {
  display: table;
  width: 100%;
}
.admin-notice {
  background: #f5effc none repeat scroll 0 0;
  border-top: 4px solid #e5e5c6;
  font-family: arial;
  font-size: 20px;
  margin: 20px 0;
  padding: 15px 30px;
}
.corners {
  border-radius: 0;
}
.admin-panel-row {
  display: table;
  width: 100%;
  padding: 5px 0;
}
.admin-panel-btn {
  margin-top: 15px;
}
.admin-panel-title {
  font-family: alfa;
  font-size: 20px;
  color: #858585;
}
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.admin-panel-section.alertsection {
  background: none repeat scroll 0 0 #f2dede;
}
.admin-panel-section {
  background: #cccccc none repeat scroll 0 0;
  border: medium none !important;
  margin: 14px -18px -11px;
  padding: 10px 17px;
  position: relative;
}
.admin-panel-section.alertsection {
  background: none repeat scroll 0 0 #f2dede;
}
.no-margin-top {
  margin-top: 0;
}
.no-margin-bot {
  margin-bottom: 0;
}
.no-margin-left {
  margin-left: 0;
}
.no-margin-right {
  margin-right: 0;
}
a,
a:active,
a,
button:focus {
  outline: none;
}
a,
a:active,
a,
button:focus {
  outline: none !important;
}
/* zero clipboard messages */
.copy-to-clipboard {
  position: relative;
  width: 130px;
  margin: 0 10px;
}
.copy-to-clipboard input {
  border: 1px solid #2f3264;
  cursor: pointer;
  font-size: 12px;
  height: 25px;
  width: 100%;
}
.copy-to-clipboard .copied-message {
  /*position: absolute;
		top: -1px;
		left: 20px;*/
  margin: 0 auto;
  background: #9ff39f;
  width: 80px;
  border-radius: 8px;
  opacity: 0.95;
}
.points-calculator-selection-logic {
  background: rgba(0, 0, 0, 0.05);
  display: flex;
  width: 110%;
  margin: 0 -15px;
}

.points-calculator-selection-padding {
  padding: 20px 10px;
  width: 100%;
}
.points-calculator-data {
  margin: 0 -15px;
}

.points-calculator-data table {
  border: none;
}

.points-calculator-data table td {
  border-bottom: 1px solid #d8d5de !important;
  padding: 10px 15px;
  border: none;
}
.calculator-header {
  font-size: 15px;
  position: relative;
  top: -5px;
}
.logged-in-as-alert-logic {
  position: absolute;
  left: 50%;
  margin: 0 0 0 -22%;
  -webkit-box-shadow: 0 0 10px -2px #000;
  width: 44%;
  box-shadow: 0 0 10px -2px #000;
  min-width: auto;
  top: 0;
}
.logged-in-as-alert-content {
  margin: auto;
  display: table;
}

.logged-in-as-alert-padding {
  background: #f44336;
  padding: 5px 30px;
  border-radius: 0 0 5px 5px;
  color: #fff;
  font-weight: bold;
}
.previous-login {
  background: #fff !important;
  color: #2a2f69 !important;
  margin-left: 12px !important;
}

/* Responsive Program
-------------------------------------------------------------- */
@media screen and (min-height: 750px) {
  .quartz-side-nav {
    height: 100%;
  }
}

@media screen and (min-width: 1025px) {
  .quartz-side-nav-overflow {
    width: calc(100% + 19px);
  }
}
@media screen and (max-width: 1500px) {
}

@media screen and (max-width: 1170px) {
  .quartz-side-nav > ul > li > a {
    padding: calc(1rem + 0) 0 1rem;
  }
  .navigation-icon {
    float: none;
    text-align: center;
  }
  .navigation-label {
    display: block;
    margin: 0;
    text-align: center;
  }
  .nav-menu-item > a {
    padding-left: 10px !important;
  }
  .secondmenulogic {
    left: 180px;
  }
  .touredit-col {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .quartz-side-nav > ul > li > a {
    padding: calc(1rem + 0) 0 1rem;
  }
  .navigation-icon {
    float: left;
    text-align: center;
  }
  .navigation-label {
    display: table;
    margin: 2px 0 0 36px !important;
  }
  .nav-menu-item > a {
    padding-left: 15px !important;
  }
  .quartz-side-nav.nav-is-visible {
    height: 100%;
    overflow-y: scroll;
    position: fixed;
    visibility: visible;
  }
  .nav-is-visible .quartz-top-nav {
    margin-bottom: -17px;
  }

  .mainMenu {
    height: 45px;
    width: 15px;
  }
  .secondmenulogic {
    left: 0;
    top: 45px;
  }
  .plb-welcome-logic.desktop {
    display: none;
  }
  .gridview-logic td {
    font-size: 16px;
  }
  .report-row.right {
    text-align: left;
  }
  .pagination-logic {
    min-height: 30px;
  }
  .pagination {
    position: absolute;
  }
  .grid-tournaments th:nth-child(3) {
    display: none;
  }
  .grid-tournaments td:nth-child(3) {
    display: none;
  }
  .grid-tournaments th:nth-child(4) {
    display: none;
  }
  .grid-tournaments td:nth-child(4) {
    display: none;
  }
  .grid-players th:nth-child(3) {
    display: none;
  }
  .grid-players td:nth-child(3) {
    display: none;
  }
  .grid-staff th:nth-child(4) {
    display: none;
  }
  .grid-staff td:nth-child(4) {
    display: none;
  }
  .grid-venues th:nth-child(2) {
    display: none;
  }
  .grid-venues td:nth-child(2) {
    display: none;
  }
  .grid-seasons th:nth-child(4) {
    display: none;
  }
  .grid-seasons td:nth-child(4) {
    display: none;
  }
  .grid-seasons th:nth-child(5) {
    display: none;
  }
  .grid-seasons td:nth-child(5) {
    display: none;
  }
  .grid-events th:nth-child(5) {
    display: none;
  }
  .grid-events td:nth-child(5) {
    display: none;
  }
  .grid-ads th:nth-child(2) {
    display: none;
  }
  .grid-ads td:nth-child(2) {
    display: none;
  }
  .grid-scoring th:nth-child(1) {
    width: 30%;
  }
  .mat-paginator-page-size-label {
    display: none !important;
  }
}
@media screen and (max-width: 650px) {
  .container,
  .container-fluid {
    padding: 0 0rem 3rem !important;
  }
  .secondmenulogic {
    padding-left: 0;
  }
  #tournament_stats .gridview-logic .gridRow td:nth-child(4) {
    display: table-cell;
  }
  #tournament_stats .gridview-logic .gridRow td:nth-child(3) {
    text-align: left !important;
  }
  #tournament_stats .gridview-logic table tbody tr th:nth-child(4) {
    display: table-cell;
  }
  .custom-profile-grid {
    width: 200%;
  }
  .grid-tournaments td:nth-child(3) {
    display: none;
  }
  .grid-tournaments td:nth-child(5) {
    display: none;
  }
  .grid-tournaments td:nth-child(6) {
    display: none;
  }
  .grid-tournaments th:nth-child(3) {
    display: none;
  }
  .grid-tournaments th:nth-child(5) {
    display: none;
  }
  .grid-tournaments th:nth-child(6) {
    display: none;
  }
  .grid-seasons td:nth-child(4) {
    display: none;
  }
  .grid-seasons th:nth-child(4) {
    display: none;
  }
  .grid-seasons td:nth-child(5) {
    display: none;
  }
  .grid-seasons th:nth-child(5) {
    display: none;
  }
  .grid-events td:nth-child(2) {
    display: none;
  }
  .grid-events th:nth-child(2) {
    display: none;
  }
  .grid-events td:nth-child(5) {
    display: none;
  }
  .grid-events th:nth-child(5) {
    display: none;
  }
  .grid-staff th:nth-child(3) {
    display: none;
  }
  .grid-staff td:nth-child(3) {
    display: none;
  }
  .grid-staff th:nth-child(4) {
    display: none;
  }
  .grid-staff td:nth-child(4) {
    display: none;
  }
  .grid-venues th:nth-child(2) {
    display: none;
  }
  .grid-venues td:nth-child(2) {
    display: none;
  }

  .tournament-details-panel {
    margin: -6px 0 15px;
  }
  .recent-activity th:nth-child(3) {
    display: none;
  }
  .recent-activity th:nth-child(4) {
    display: none;
  }
  .recent-activity .gridRow td:nth-child(4) {
    display: none;
  }
  .grid-dealeractivity th:nth-child(2) {
    display: none;
  }
  .grid-dealeractivity td:nth-child(2) {
    display: none;
  }
  .grid-dealeractivity th:nth-child(3) {
    display: none;
  }
  .grid-dealeractivity td:nth-child(3) {
    display: none;
  }
  .grid-playeractivity th:nth-child(2) {
    display: none;
  }
  .grid-playeractivity td:nth-child(2) {
    display: none;
  }
  .grid-playeractivity th:nth-child(3) {
    display: none;
  }
  .grid-playeractivity td:nth-child(3) {
    display: none;
  }
  .grid-playeractivity th:nth-child(5) {
    display: none;
  }
  .grid-playeractivity td:nth-child(5) {
    display: none;
  }
  #innerContent {
    margin-top: 70px;
  }
  .ad-card img {
    width: 90% !important;
  }
  .plb-welcome-logic {
    padding: 5px 0 4px;
  }
  .itemrow {
    padding-top: 30px;
  }
  .action-to-top-btn {
    right: 7px;
  }
  .content-logic .alert {
    top: 50px;
    left: 0;
    width: 100% !important;
  }
}
@media screen and (max-width: 480px) {
  .card-membership-iscurrent-content {
    display: block;
    margin: 3px 0 15px;
  }
  .card-membership-iscurrent-header {
    display: none;
  }
  .uploadfield {
    width: 48%;
  }
  .remove-avatar {
    width: 31%;
  }
  .card .gridview-logic {
    overflow-x: auto;
  }
  .grid-tournaments table {
    width: 300% !important;
    max-width: none;
  }
  .grid-players table {
    width: 300% !important;
    max-width: none;
  }
  .grid-staff table {
    width: 200% !important;
    max-width: none;
  }
  .grid-venues table {
    width: 300% !important;
    max-width: none;
  }
  .grid-seasons table {
    width: 300% !important;
    max-width: none;
  }
  .grid-activity table {
    width: 300% !important;
    max-width: none;
  }
  .grid-playeractivity table {
    width: 300% !important;
    max-width: none;
  }
  .grid-dealeractivity table {
    width: 300% !important;
    max-width: none;
  }
  .grid-scoring table {
    width: 300% !important;
    max-width: none;
  }
  .grid-events table {
    width: 300% !important;
    max-width: none;
  }
  .grid-activity th:nth-child(1) {
    display: none;
  }
  .grid-activity td:nth-child(1) {
    display: none;
  }
  .mat-menu-panel {
    max-width: none;
  }
  .dashboard-panel-heading {
    font-size: 25px;
    line-height: 27px;
  }
  .nonpayment-section {
    margin: auto;
  }
  .mat-paginator-range-label {
    display: none !important;
  }
  .mat-paginator-page-goto {
    left: 175px;
  }
  .quickstat-size-1 {
    font-size: 55px;
  }
}
